import React, { useEffect, useRef, useState } from "react";
import { BASE_URL } from "../../utils/Element";
import Modal from "../Modal/Modal";
import gif from "../../assets/gif/inbox.gif";
import { Radio, RadioGroup,Stack } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import { NotificationManager } from "react-notifications";
import axios from "axios";
import useAuth from "../../hooks/useAuth";
import Select from "react-select";

const initialData = {
  bds: null,       
  broker: null,    
  created_at: "",
  lead_source: null,
  leadType: "", 
};

const MarkAsLeadConfirmationModal = ({ showModal, onClose, handleAction, isLead }) => {
  const [value, setValue] = useState("Disposition");
  const [leadSourceOptions, setLeadSourceOptions] = useState([]);
  const [config] = useAuth();
   const [loading, setLoading] = useState(false);
  const [bdsOptions, setBdsOptions] = useState([]);
  const [brokerOptions, setBrokerOptions] = useState([]);
  const [formData, setFormData] = useState(initialData);
  const [error, setError] = useState();
  const selectRef = useRef(null);

  const handleConfirm = () => {
    const leadType = value === "Acquisition" ? 1 : 0; 
     const payload = {
      ...formData,
      leadType, 
    };
    handleAction(payload); 
  };

  const handleChange = (value, name) => {
    setError((prev) => ({ ...prev, [name]: "" }));
    setFormData((prev) => ({ ...prev, [name]: value || null })); 
  };

  const fetchLeadSources = () => {
    setLoading(true);
    axios
      .get(`${BASE_URL}/get-datalisting?list=lead_sources`, config)
      .then((res) => {
        const data = res?.data?.data;
        const options = data?.lead_sources?.map((el) => ({ value: el?.id, label: el.name }));
        setLeadSourceOptions(options);
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      })
      .finally(() => setLoading(false));
  };

  const fetchUsers = () => {
    setLoading(true);
    axios
      .get(`${BASE_URL}/get-datalisting?list=users`, config)
      .then((res) => {
        const data = res?.data?.data;
        const options = data?.map((el) => ({ value: el?.id, label: `${el?.first_name} ${el?.last_name}`, role: el?.role_id }));
        setBdsOptions(options);
        setBrokerOptions(options?.filter((el) => el.role === 3));
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (showModal) {
      fetchUsers();
      fetchLeadSources();
      setFormData(initialData);
    }
  }, [showModal]);

  return (
    <Modal show={showModal} onClose={onClose}>
      <form className="py-3">
        <div className="flex justify-center mt-[-20px]">
          <img src={gif} alt="" height={120} width={120} />
        </div>

        <div className="flex-1 mt-4 text-center w-30">
          <p className="head-1 dark-H font-bold">{isLead ? "Mark as" : "Unmark"} lead?</p>
          <p className="py-1 body-N dark-M mb-3">
            Are you sure to {isLead ? "mark as" : "unmark"} lead in this contact?
            This action can be reversed anytime.
          </p>
        </div>

        <div className="mt-3">

        <div className="flex mt-4 gap-4">
            <div className="w-[48%] mt-4">
            <label className="dark-H head-4 mb-2">BDS</label>
            <Select
               ref={selectRef}
               className="body-N"
               options={bdsOptions}
               placeholder="Select"
               value={bdsOptions.find((option) => option?.value === formData.bds) || null} 
               onChange={(option) => handleChange(option?.value, "bds")}
             />
             {console.log("formData" , formData)}
            </div>

            <div className=" w-[48%] mt-4">
            <label className="dark-H head-4 mb-2">Broker<span className="red-D">*</span></label>
            <Select
              ref={selectRef}
              className="body-N"
              options={brokerOptions}
              placeholder="Select"
              value={brokerOptions.find((option) => option?.value === formData.broker) || null}
              onChange={(option) => handleChange(option?.value, "broker")}
            />
            </div>
        </div>

        <div className="flex mt-4 gap-4">
          <div className="w-[48%] mt-3">
            <label className="dark-H head-4 mb-2">Lead Date</label>
            <DatePicker
              className="body-N"
              dateFormat="MM/dd/yyyy"
              placeholderText="MM/DD/YYYY"
              selected={formData.created_at ? new Date(formData.created_at) : null}
              onChange={(value) => handleChange(value, "created_at")}
            />
            {error?.created_at && <p className="body-S red-D">{error?.created_at}</p>}
          </div>

          <div className="w-[48%] mt-4">
            <label className="dark-H head-4 mb-2">Lead Source<span className="red-D">*</span></label>
            <Select
              className="body-N"
              options={leadSourceOptions}
              placeholder="Select"
              value={leadSourceOptions?.find((option) => option.value === formData.lead_source) || null}
              onChange={(option) => handleChange(option.value, "lead_source")}
            />
            {error?.lead_source_id && <span className="body-S red-D">{error?.lead_source_id}</span>}
          </div>
        </div>
       
          <div className="flex gap-3 mt-4">
            <RadioGroup onChange={setValue} value={value}>
              <Stack direction="row" gap={5}>
                <Radio value="Disposition">Disposition</Radio>
                <Radio value="Acquisition">Acquisition</Radio>
              </Stack>
            </RadioGroup>
          </div>

         </div>

        <div className="mt-6 flex justify-center">
          <button type="button" onClick={handleConfirm} className="save-button light-L head-5 green-bg-H">
            Confirm
          </button>
          <button type="button" onClick={onClose} className="green-H body-N ml-5">
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default MarkAsLeadConfirmationModal;
