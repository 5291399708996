import React, { useState } from "react";
import axios from "axios";
import useAuth from "../../hooks/useAuth";
import { BASE_URL } from "../../utils/Element";
import Modal from "../../components/Modal/Modal";
import AddOfferData from "./components/AddOfferData";
import { NotificationManager } from "react-notifications";
import { getPercentAskingPrice } from "../../utils/utils";

const initialData = {
  list_type: "",
  type_id: "",
  user_type: "buyer",
  offer_cap_rate: "",
  offer_price: "",
  property_name: "",
  asking_price: "",
  broker_buyer: "",
  bds_buyer: "",	
  broker_seller: "",
  bds_seller: "",
  bds_buyer_id :"",
  bds_seller_id :"",
  br_seller_id :"",
  br_buyer_id:"",
  created_at:"",
  offer_version:"",
  buyer_exclusive_id:"",
  buyer_lead_id:"",
  seller_exclusive_id:"",
  seller_lead_id:"",
  buyer_contact_id:"",
  seller_contact_id:"",
  seller_options:"",
  buyer_options:"",

};

const AddOfferModal = ({ showModal, onClose, onCallApi, id }) => {
  const [config] = useAuth();
  const [error, setError] = useState();
  const [disable, setDisable] = useState(false);
  const [formData, setFormData] = useState(initialData);

  const handleClose = () => {
    setFormData(initialData);
    onClose();
    setError();
    setDisable(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisable(true);
  
    const percent_of_asking_price = getPercentAskingPrice(formData?.offer_price, formData?.asking_price);
     const addDataToSend = {
      // exclusive_id: formData?.list_type === "exclusive" ? formData?.type_id : null,
      // lead_id: formData?.list_type !== "exclusive" ? formData?.type_id : null,
      offer_price: formData?.offer_price,
      offer_cap_rate: formData?.offer_cap_rate,
      broker_seller: formData?.br_seller_id,
      bds_seller: formData?.bds_seller_id,
      broker_buyer: formData?.br_buyer_id,
      bds_buyer: formData?.bds_buyer_id,
      created_at: formData?.created_at,
      offer_version: formData?.offer_version,
      buyer_exclusive_id: formData?.buyer_exclusive_id, 
      buyer_lead_id: formData?.buyer_lead_id,
      buyer_contact_id: formData?.buyer_contact_id,
      seller_exclusive_id:formData?.seller_exclusive_id, 
      seller_lead_id:formData?.seller_lead_id, 
      seller_contact_id:formData?.seller_contact_id, 
      type: formData?.user_type,
      percent_of_asking_price: percent_of_asking_price,
    };
  
    const updateDataToSend = {
      offer_price: formData?.offer_price,
      offer_cap_rate: formData?.offer_cap_rate,
      broker_seller: formData?.br_seller_id,
      bds_seller: formData?.bds_seller_id,
      broker_buyer: formData?.br_buyer_id,
      bds_buyer: formData?.bds_buyer_id,
      created_at: formData?.created_at,
      offer_version: formData?.offer_version,
      buyer_options: formData?.buyer_exclusive_id, 
      buyer_options: formData?.buyer_lead_id,
      buyer_options: formData?.buyer_contact_id,
      seller_exclusive_id:formData?.seller_exclusive_id, 
      seller_lead_id:formData?.seller_lead_id, 
      seller_contact_id:formData?.seller_contact_id, 
      type: formData?.user_type,
      offer_id: id,
    };
  
    const dataToSend = id === "" ? addDataToSend : updateDataToSend;
    const url = id === "" ? "add-offer?type=new" : "add-offer";
  
    axios
      .post(`${BASE_URL}/${url}`, dataToSend, config)
      .then((res) => {
        handleClose();
        onCallApi();
      })
      .catch((err) => {
        setDisable(false);
        setError(err.response?.data?.errors);
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      });
  };
  
  
  

  return (
    <Modal width = "620px" title={`Add Offer`} desc={`Add the offer information.`} show={showModal} onClose={handleClose}>
      <AddOfferData
        formData={formData}
        error={error}
        onSetFormData={(value) => {
          setFormData(value);
        }}
        onSetError={(value) => setError(value)}
        handleSubmit={handleSubmit}
        handleClose={handleClose}
        showModal={showModal}
        from={id === "" ? "new" : "update"}
        disable={disable}
      />
    </Modal>
  );
};

export default AddOfferModal;
