import Modal from "../Modal/Modal";
import React, { useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../utils/Element";
import useAuth from "../../hooks/useAuth";
import { NotificationManager } from "react-notifications";


const MailChipModal = ({showModal, onClose, onSuccess , selectedItem }) => {
  const [config] = useAuth();
  const [error, setError] = useState();
  const [disable, setDisable] = useState(false);
 

    const handleClose = () => {
        setError();
        onClose();
      };


      const handleConfirm = (e) => {
        e.preventDefault();
        setDisable(true);
        const dataToSend = {
          action: "MailChip", 
          items: selectedItem,
        };
           axios
          .post(`${BASE_URL}/sendContact`,dataToSend, config)
          .then((res) => {
            onSuccess();
            handleClose();
          })
          .catch((err) => {
          if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
            NotificationManager.error(err.response?.data?.message);
          }
        })
      };

 return(
    <>
    

    <Modal width="540px" show={showModal} onClose={onClose}>
      <form onSubmit={handleConfirm} className="text-center">
        <div className="flex-1 text-center w-30">
          <p className="head-1 dark-H font-bold">Mailchimp </p>
          <p className="py-1 dark-M body-L">
            Are you sure you want to Sync this contact to Mailchimp?
          </p>

        </div>


        <div className="mt-6 mb-6 flex justify-center">
          <button
            type="submit"
            className="save-button light-L head-5 green-bg-H px-[30px] py-[12px]"
          >
            Sync
          </button>
          <button
            type="button"
            onClick={handleClose}
            className="green-H body-N ml-5"
          >
            Cancel
          </button>
        </div>
      </form>
    </Modal>
    
    
     
    
    
    
    
    
    
    
    
    
    
    </>
 )


}


export default MailChipModal;