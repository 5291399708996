import React, { useEffect, useState } from "react";
import axios from "axios";
import Modal from "../Modal/Modal";
import useAuth from "../../hooks/useAuth";
import gif from "../../assets/gif/remove.gif";
import { BASE_URL } from "../../utils/Element";
import Select from "react-select";
import { NotificationManager } from "react-notifications";



export const communicationData = [
  { label: "Do Not Send", value: "do not send" },
  { label: "Do Not Blast", value: "do not blast" },
  { label: "Bad #", value: "bad" },
];

const RemoveTagConfirmationModal = ({ showModal, onClose, onSuccess, from, selectedItem ,onSetTags }) => {
  const [config] = useAuth();
  const [error, setError] = useState();
  const [selectedValues, setSelectedValues] = useState([]);
  const [contactTagOptions, setContactTagOptions] = useState([]);
  const [propertyTagOptions, setPropertyTagOptions] = useState([]);

const fetchContactTags = () => {
    axios
      .get(`${BASE_URL}/get-datalisting?list=contact_tags`, config)
      .then((res) => {
        const value = res?.data?.data?.contact_tags;
        setContactTagOptions(value?.map((el) => ({ value: el?.id, label: el?.tag_name })))
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      });
  };

 const fetchPropertyTags = () => {
    axios
      .get(`${BASE_URL}/get-datalisting?list=property_tag`, config)
      .then((res) => {
        const value = res?.data?.data?.property_tag;
        setPropertyTagOptions(value?.map((el) => ({ value: el?.id, label: el?.tag_name })));
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      });
  };

    useEffect(() => {
    if (from === "contact" && showModal) {
      fetchContactTags();
    } else if (from === "property" && showModal) {
      fetchPropertyTags();
    }
  }, [from, showModal]);


    let optionsToUse = [];
  if (from === "contact") {
    optionsToUse = contactTagOptions;
  } else if (from === "property") {
    optionsToUse = propertyTagOptions;
  } else if (from === "company") {
    optionsToUse = communicationData;
  }

  const handleClose = () => {
    setError();
    onClose();
  };

  const handleConfirm = (e) => {
    e.preventDefault();

    const dataToSend = {
      action: "remove_tag",
      ids: selectedItem,
      tag: selectedValues,
      type: from,
    };
    axios
      .post(`${BASE_URL}/handleBulk-Actions`, dataToSend, config)
      .then((res) => {
        onSuccess();
        handleClose();
        if (res?.data?.message) {
               NotificationManager.success(res?.data?.message);
         }
      })
      .catch((error) => {
        console.error("Error add activity:", error);
        setError(error.response.data.message);
      });
  };

  return (
    <Modal width="450px" show={showModal} onClose={onClose}>
      <form onSubmit={handleConfirm} className="py-3 ">
        <div className="flex justify-center mt-[-20px]">
          <img src={gif} alt="binimg" height={120} width={120} />
        </div>

        <div className="flex-1 mt-4 text-center w-30">
          <p className="head-1 dark-H font-bold">Remove Tag</p>
          <p className="py-1 dark-M body-L">Are you sure you want to remove these tag?</p>
        </div>

          <div className="mt-2">
            <Select
              isMulti
              className="service-area body-L dark-M "
              placeholder="Remove tag here"
              options={optionsToUse}
              value={optionsToUse?.filter((el) => selectedValues.includes(el.value))}
              onChange={(options) => {
                const values = options?.map((el) => el.value);
                setSelectedValues(values);
                onSetTags(values);
              }}
            />
          </div>

       {error && <p className="body-S red-D">{error}</p>}
      

        <div className="mt-6 flex justify-center">
          <button type="submit" className="save-button light-L head-5 green-bg-H px-[30px] py-[12px]">
             Remove Tag
          </button>
          <button type="button" onClick={handleClose} className="green-H body-N ml-5">
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default RemoveTagConfirmationModal;
