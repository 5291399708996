import React, { useEffect, useState } from "react";
import axios from "axios";
import useAuth from "../../hooks/useAuth";
import Loader from "../../components/Loader";
import { BASE_URL } from "../../utils/Element";
import Modal from "../../components/Modal/Modal";
import AddOfferData from "./components/AddOfferData";
import { NotificationManager } from "react-notifications";
import { getPercentAskingPrice } from "../../utils/utils";

const initialData = {
  list_type: "",
  type_id: "",
  user_type: "buyer",
  offer_cap_rate: "",
  offer_price: "",
  property_name: "",
  asking_price: "",
  bds_buyer_id :"",
  bds_seller_id :"",
  br_seller_id :"",
  br_buyer_id:"",
  created_at:"",
  version:"",
  seller_bds:"",
  seller_broker:"",
  buyer_bds:"",
  buyer_broker:"",
  buyer_exclusive_id:"",
  buyer_lead_id:"",
  seller_exclusive_id:"",
  seller_lead_id:"",
  buyer_contact_id:"",
  seller_contact_id:"",
  seller_list_type:"",
};

const EditOfferModal = ({ showModal, onClose, onCallApi, id, leadId }) => {
  const [config] = useAuth();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(false);
  const [formData, setFormData] = useState(initialData);

  useEffect(() => {
    if (id && showModal) {
      setLoading(true);
      axios
        .get(`${BASE_URL}/offer-getby-Id/${id}`, config)
        .then((res) => {
          const data = res?.data;
  
          const listType = data?.buyer_exclusive_id 
            ? "exclusive"
            : data?.buyer_lead_id 
            ? "leads"
            : data?.buyer_contact_id 
            ? "contacts"
            : "";

            const sellerlistType = data?.seller_exclusive_id
            ? "exclusive"
            : data?.seller_lead_id
            ? "leads"
            : data?.seller_contact_id
            ? "contacts"
            : "";
            setFormData({
            type_id: data?.lead_id,
            offer_price: data?.offer_price,
            offer_cap_rate: data?.offer_cap_rate,
  
            list_type: listType, 
            seller_list_type:sellerlistType,
  
            seller_exclusive_id: data?.seller_exclusive_id,
            seller_lead_id: data?.seller_lead_id,
            seller_contact_id: data?.seller_contact_id,
  
            buyer_exclusive_id: data?.buyer_exclusive_id,
            buyer_lead_id: data?.buyer_lead_id,
            buyer_contact_id: data?.buyer_contact_id,
  
            buyer_options: data?.acquistion_name,
            seller_options: data?.disposition_name,  
  
            broker_seller: data?.seller_broker 
              ? `${data.seller_broker.first_name || ''} ${data.seller_broker.last_name || ''}`.trim() 
              : '',
            bds_seller: data?.seller_bds 
              ? `${data.seller_bds.first_name || ''} ${data.seller_bds.last_name || ''}`.trim() 
              : '',
            broker_buyer: data?.buyer_broker 
              ? `${data.buyer_broker.first_name || ''} ${data.buyer_broker.last_name || ''}`.trim() 
              : '',
            bds_buyer: data?.buyer_bds 
              ? `${data.buyer_bds.first_name || ''} ${data.buyer_bds.last_name || ''}`.trim() 
              : '',
            created_at: data?.created_at,
            offer_version: data?.version,
            user_type: data?.type,
          });
        })
        .catch((err) => {
          if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
            NotificationManager.error(err.response?.data?.message);
          }
        })
        .finally(() => setLoading(false));
    }
  }, [id, showModal]);
  
  const handleClose = () => {
    setFormData(initialData);
    onClose();
    setError();
    setDisable(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisable(true);
    const percent_of_asking_price = getPercentAskingPrice(formData?.offer_price, formData?.offer_cap_rate);
    const dataToSend = {
      offer_price: formData?.offer_price,
      offer_cap_rate: formData?.offer_cap_rate,
      type: formData?.user_type,
      percent_of_asking_price: percent_of_asking_price,
      broker_seller: formData?.br_seller_id,
      bds_seller: formData?.bds_seller_id,
      broker_buyer: formData?.br_buyer_id,
      bds_buyer: formData?.bds_buyer_id,
      created_at: formData?.created_at,
      offer_version: formData?.offer_version,
      buyer_exclusive_id: formData?.buyer_exclusive_id, 
      buyer_lead_id: formData?.buyer_lead_id,
      buyer_contact_id: formData?.buyer_contact_id,
      seller_exclusive_id:formData?.seller_exclusive_id, 
      seller_lead_id:formData?.seller_lead_id, 
      seller_contact_id:formData?.seller_contact_id,
      offer_version: formData?.offer_version,
    };

    axios
      .post(`${BASE_URL}/update-offer/${id}`, dataToSend, config)
      .then((res) => {
        handleClose();
        onCallApi();
      })
      .catch((err) => {
        setDisable(false);
        setError(err.response?.data?.errors);
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      });
  };

  return (
    <Modal width = "620px" title={`Edit Offer`} desc={`Edit the offer information.`} show={showModal} onClose={handleClose}>
      {loading ? (
        <Loader />
      ) : (
        <AddOfferData
          formData={formData}
          error={error}
          onSetFormData={(value) => {
            setFormData(value);
          }}
          onSetError={(value) => setError(value)}
          handleSubmit={handleSubmit}
          handleClose={handleClose}
          showModal={showModal}
          disable={disable}
        />
      )}
    </Modal>
  );
};

export default EditOfferModal;
