import React, { useState } from "react";
import axios from "axios";
import Modal from "../Modal/Modal";
import useAuth from "../../hooks/useAuth";
import gif from "../../assets/gif/remove.gif";
import { BASE_URL } from "../../utils/Element";
import { Link } from "@chakra-ui/react";

const UnMergeConfirmationModal = ({ showModal, onClose, onSuccess, from, selectedItem }) => {
  const [config] = useAuth();
  const [error, setError] = useState();

  const typeNames = {
    contact: "contact",
    company: "company",
    property: "property",
  };

  const handleClose = () => {
    setError();
    onClose();
  };

  const handleConfirm = (e) => {
    e.preventDefault();
  const dataToSend = {
      action: "UnMerge",
      items: selectedItem,
      type: from, 
    };

    axios
      .post(`${BASE_URL}/get-merged-contacts`, dataToSend, config)
      .then((res) => {
        onSuccess();
        handleClose();
      })
      .catch((error) => {
        console.error("Error merging contacts:", error);
        setError(error.response?.data?.message || "An error occurred.");
      });
  };

  return (
    <Modal width="700px" show={showModal} onClose={onClose}>
      <form onSubmit={handleConfirm} className="text-center">
        <div className="flex-1 text-center w-30">
          <p className="head-1 dark-H font-bold">UnMerge {typeNames[from]}</p>
          <p className="py-1 dark-M body-L">
             Are you sure you want to UnMerge the {typeNames[from]}? 
          </p>
        </div>

        <div className="mt-6 mb-6 flex justify-center">
          <button
            type="submit"
            className="save-button light-L head-5 green-bg-H px-[30px] py-[12px]"
          >
            Merge
          </button>
          <button
            type="button"
            onClick={handleClose}
            className="green-H body-N ml-5"
          >
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default UnMergeConfirmationModal ;


