import React, { useState } from "react";
import axios from "axios";
import Modal from "../Modal/Modal";
import useAuth from "../../hooks/useAuth";
import gif from "../../assets/gif/remove.gif";
import { BASE_URL } from "../../utils/Element";
import { Link } from "@chakra-ui/react";

const MergeConfirmationModals = ({ showModal, onClose, onSuccess, from, selectedItem }) => {
  const [config] = useAuth();
  const [error, setError] = useState();
  const [principalId, setPrincipalId] = useState(null); 

  const typeNames = {
    contact: "contact",
    company: "company",
    property: "property",
  };

  const handleClose = () => {
    setError();
    onClose();
  };

  const handleConfirm = (e) => {
    e.preventDefault();

    if (!principalId) {
      setError("Please select a principal item before proceeding.");
      return;
    }

    const dataToSend = {
      action: "Merge-contact",
      items: selectedItem,
      type: from,
      principal_id: principalId, 
    };

    axios
      .post(`${BASE_URL}/get-merged-contacts`, dataToSend, config)
      .then((res) => {
        onSuccess();
        handleClose();
      })
      .catch((error) => {
        console.error("Error merging contacts:", error);
        setError(error.response?.data?.message || "An error occurred.");
      });
  };

  return (
    <Modal width="700px" show={showModal} onClose={onClose}>
      <form onSubmit={handleConfirm} className="text-center">
        <div className="flex-1 text-center w-30">
          <p className="head-1 dark-H font-bold">Merge {typeNames[from]}</p>
          <p className="py-1 dark-M body-L">
            Please choose the PRIMARY {typeNames[from]} for merge
          </p>

          <ul className="py-2">
            {Array.isArray(selectedItem) ? (
              selectedItem.map((item) => (
                <li key={item.id} className="body-S dark-H items-center flex gap-3 py-2">
                  <div>
                    <input
                      type="radio"
                      id={`radio-${item.id}`}
                      name="selectedContact"
                      value={item.id}
                      className="mr-2"
                      onChange={() => setPrincipalId(item.id)} 
                      checked={principalId === item.id}
                    />
                  </div>
                  <label htmlFor={`radio-${item.id}`} className="cursor-pointer">
                    <div className="flex justify-between items-center w-[620px] gap-4">
                      <p className="font-bold w-1/4">
                        {from === "contact" ? (
                          <Link to={`/contact/${item.id}`}>{item.name || "N/A"}</Link>
                        ) : from === "company" ? (
                          <Link to={`/company/${item.id}`}>{item.company_name || "N/A"}</Link>
                        ) : from === "property" ? (
                          <Link to={`/property/${item.id}`}>{item.property_name || "N/A"}</Link>
                        ) : (
                          "N/A"
                        )}
                      </p>
                      <p className="w-1/4 flex flex-col">
                        <span className="font-semibold">Phone:</span> {item.phone?.[0]?.phone_number || "N/A"}
                      </p>
                      <p className="w-1/4 flex flex-col">
                        <span className="font-semibold">Email:</span> {item.email?.[0]?.email_id || "N/A"}
                      </p>
                      <p className="w-1/4 flex flex-col">
                        <span className="font-semibold">Address:</span> 
                        {from === "property" 
                          ? item.street_address || "N/A" 
                          : item.address?.[0]?.street || "N/A"}
                      </p>
                    </div>
                  </label>
                </li>
              ))
            ) : (
              <li className="body-S dark-H flex items-start py-2 gap-4">
                <div>
                  <input
                    type="radio"
                    id={`radio-${selectedItem?.id}`}
                    name="selectedContact"
                    value={selectedItem?.id}
                    className="mr-2"
                    onChange={() => setPrincipalId(selectedItem?.id)} 
                    checked={principalId === selectedItem?.id}
                  />
                </div>
                  <label htmlFor={`radio-${selectedItem?.id}`} className="cursor-pointer">
                  <div className="flex justify-between items-cente w-[620px] gap-4">
                    <p className="font-bold w-1/4">
                      {from === "contacts" ? (
                        <Link to={`/contact/${selectedItem?.id}`}>{selectedItem?.name || "N/A"}</Link>
                      ) : from === "company" ? (
                        <Link to={`/company/${selectedItem?.id}`}>{selectedItem?.company_name || "N/A"}</Link>
                      ) : from === "property" ? (
                        <Link to={`/property/${selectedItem?.id}`}>{selectedItem?.property_name || "N/A"}</Link>
                      ) : (
                        "N/A"
                      )}
                    </p>
                    <p className="w-1/4">
                      <span className="font-semibold">Phone:</span> {selectedItem?.phone?.[0]?.phone_number || "N/A"}
                    </p>
                    <p className="w-1/4">
                      <span className="font-semibold">Email:</span> {selectedItem?.email?.[0]?.email_id || "N/A"}
                    </p>
                    <p className="w-1/4">
                      <span className="font-semibold">Address:</span> {selectedItem?.address?.[0]?.street || "N/A"}
                    </p>
                  </div>
                </label>
              </li>
            )}
          </ul>
        </div>

        {error && <p className="body-S red-D">{error}</p>}

     
          <p className="py-1 dark-M body-L">
            Are you sure you want to merge the {typeNames[from]}?  
          </p>     

        <div className="mt-6 mb-6 flex justify-center">
          <button
            type="submit"
            className="save-button light-L head-5 green-bg-H px-[30px] py-[12px]"
          >
            Merge
          </button>
          <button
            type="button"
            onClick={handleClose}
            className="green-H body-N ml-5"
          >
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default MergeConfirmationModals;


