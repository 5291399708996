import React from "react";
import BaseLayout from "../../layouts/BaseLayout";



const Mailchimp = () => {
    return(
        <BaseLayout>
          <p className="head-1 dark-H">
          Mailchimp
        </p>



        </BaseLayout>
        
        
    )
}


export default Mailchimp;