import React, { useState } from "react";

const CheckboxSearchOptions = ({ selectedOptions = [], handleChange, options }) => {
  const [search, setSearch] = useState("");
  const [itemToShow, setItemToShow] = useState(5);

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    const newTags = checked ? [...selectedOptions, value] : selectedOptions.filter((tag) => tag !== value);
    handleChange(newTags);
  };

  return (
    <div>
      <div className="search-box w-[100%] mt-2">
        <input
          type="text"
          className="body-S"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          placeholder="Search here...."
          style={{ width: "100%" }}
        />
      </div>

      <div className="mt-4">
        {options
          ?.filter((item) => item?.label?.includes(search))
          ?.slice(0, itemToShow)
          ?.flatMap((el, i) => (
            <label key={i} className="container">
              <input type="checkbox" value={el.value} checked={selectedOptions?.includes(el.value?.toString())} onChange={handleCheckboxChange} />
              <span className="checkmark"></span>
              <p className="dark-M body-N">{el?.label}</p>
            </label>
          ))}
      </div>

      {itemToShow === 5 ? (
        <p role="button" onClick={() => setItemToShow(options?.length)} className="dark-M body-N">
          Show More
        </p>
      ) : (
        <p role="button" onClick={() => setItemToShow(5)} className="dark-M body-N">
          Show Less
        </p>
      )}
    </div>
  );
};

export default CheckboxSearchOptions;
