import React, { useEffect, useRef, useState } from "react";
import Edit from "../assets/svgs/Pencil.svg";
import Menu from "../assets/svgs/Menu.svg";
import Delete from "../assets/svgs/Recycle Bin.svg";
import { handleDropdownClose } from "../utils/utils";

const ActionsMenu = ({
  handleEdit,
  handleDelete,
  showOtherOption,
  handleOtherOption,
  otherOptionTitle,
  otherWithdrawTitle,
  otherClosedTitle,
  handleWithdrawn,
  otherUnderContractTitle,
  handleUnderContract,
  handleClosed,
  otherConverttoExecutedLOI,
  handleConverttoExecutedLOI,
  from,
}) => {
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [showMoreOptions, setShowMoreOptions] = useState(false);

  useEffect(() => {
    const handleClose = () => {
      setIsOpen(false);
      setShowMoreOptions(false);
    };
    handleDropdownClose(dropdownRef, handleClose);
  }, []);

  return (
    <div ref={dropdownRef} className="custom-dropdown">
      <div
        role="button"
        className="light-bg-L body-N dark-M"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <img src={Menu} alt="icon" className="sidebar-icons" />
      </div>

      {isOpen && (
        <div 
          className="dropdown-list-container dropdown-end light-bg-L dark-M body-N shadow rounded-box" 
          style={{ width: "180px" }}
        >
          <ul className="dropdown-list">
            <li role="button" onClick={() => { handleEdit(); setIsOpen(false); }}>
              {!showOtherOption && otherOptionTitle !== "Convert To Pipeline" && <img src={Edit} alt="icon" className="mr-3 sidebar-icons" />} 
              Edit
            </li>
            {showOtherOption && (
              <li role="button" onClick={() => { handleOtherOption(); setIsOpen(false); }}>
                {otherOptionTitle}
              </li>
            )}
              
            {from === 'Exclusives' ? (
             <>
               <li role="button" onClick={() => { handleWithdrawn(); setIsOpen(false); }}>
                 {otherWithdrawTitle}
               </li>
               <li role="button" onClick={() => { handleClosed(); setIsOpen(false); }}>
                 {otherClosedTitle}
               </li>
              </>
              
               ) : from === 'Offers' ? (
                <>
                 <li role="button" onClick={() => { handleUnderContract(); setIsOpen(false); }}>
                   {otherUnderContractTitle}
                 </li>
                 <li role="button" onClick={() => { handleConverttoExecutedLOI(); setIsOpen(false); }}>
                   {otherConverttoExecutedLOI}
                 </li>
                 </>
              ) : null}

           
            <li className="red-D" role="button" onClick={() => { handleDelete(); setIsOpen(false); }}>
              {!showOtherOption && otherOptionTitle !== "Convert To Pipeline" && <img src={Delete} alt="icon" className="mr-3 sidebar-icons" />} 
              Delete
            </li>
           
          </ul>
        </div>
      )}
    </div>
  );
};

export default ActionsMenu;
