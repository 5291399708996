import React from "react";
import Modal from "../Modal/Modal";


const ContractModal = ({ showModal, onClose, handleUnderContract }) => {
  return (
    <Modal width="450px" show={showModal} onClose={onClose}>
      <form className="py-3 text-center">
         <div className="flex-1 mt-4 text-center w-30">
          <p className="head-1 dark-H font-bold">Contract Confirmation</p>
          <p className="py-1 dark-M body-L">Please note that confirming this contract is a final action and cannot be undone.</p>
        </div>

        <div className="mt-6 flex justify-center">
          <button type="button" onClick={handleUnderContract} className="save-button light-L head-5 green-bg-H px-[30px] py-[12px]">
            Contract
          </button>
          <button type="button" onClick={onClose} className="green-H body-N ml-5">
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default ContractModal;