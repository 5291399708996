import React from "react";
import Modal from "../Modal/Modal";


const ExpiredModal = ({ showModal, onClose, handleDelete }) => {
  return (
    <Modal width="450px" show={showModal} onClose={onClose}>
      <form className="py-3 text-center">
        <div className="flex-1 mt-2 text-center w-30">
          <p className="head-1 dark-H font-bold">Expired Confirmation</p>
          <p className="py-1 dark-M body-L">Are you sure you want to mark this as Expired?.</p>
        </div>

        <div className="mt-6 flex justify-center">
          <button type="button" onClick={handleDelete} className="save-button light-L head-5 green-bg-H px-[30px] py-[12px]">
            Expired
          </button>
          <button type="button" onClick={onClose} className="green-H body-N ml-5">
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default ExpiredModal;