import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import "./Contact.css";
import axios from "axios";
import phone from "../../assets/svgs/call.svg";
import moment from "moment";
import useAuth from "../../hooks/useAuth";
import Loader from "../../components/Loader";
import Plus from "../../assets/svgs/Plus.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BASE_URL } from "../../utils/Element";
import AddContactModal from "./AddContactModal";
import BaseLayout from "../../layouts/BaseLayout";
import { formatPhoneNumber } from "../../utils/utils";
import swap from "../../assets/svgs/swap-vertical.svg";
import ContactFilter from "./components/ContactFilter";
import ImportExport from "../../components/ImportExport";
import sortAsce from "../../assets/svgs/sort-ascending.svg";
import sortDesc from "../../assets/svgs/sort-descending.svg";
import { initialContactFilterData } from "../../utils/initialData";
import ContactPagination from "../../components/Pagination/ContactPagination";
import PhoneStatusModal from "../../components/PhoneStatusModal";
import AddContact from "./components/AddContact";

const Contacts = (contactDetails) => {
  const [config] = useAuth();
  const navigate = useNavigate();
  const [tags, setTags] = useState([]);
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [contactsData, setContactsData] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [selectedPhone, setSelectedPhone] = useState("");
  const [showPhoneModal, setShowPhoneModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [sortBy, setSortBy] = useState("updated_at");
  const [sortDirection, setSortDirection] = useState("desc");
  const isSidebarCollapsed = useSelector((state) => state.isSidebarCollapsed);
  const [paginationData, setPaginationData] = useState({ totalItems: 0, from: 0, to: 0, totalPages: 0 });
  const [filterData, setFilterData] = useState(() => {
    const localFilterData = localStorage.getItem("filterData");
    return localFilterData ? JSON.parse(localFilterData) : initialContactFilterData;
  });

  const queryParams = new URLSearchParams(location.search);
  const [search, setSearch] = useState(queryParams.get('search') ?? '');
  const contactAllIds = contactsData?.map((el) => el.id);

  const buildQueryParams = (filters) => {
    let params = `page=${currentPage}&per_page=${itemPerPage}`;
    if (search) {
        const encodedSearch = encodeURIComponent(search);
        params += `&search=${encodedSearch}`;
    }

    const { company, property, first_name, last_name, email, interaction_type_user ,phone, street_address, city, state, zip_code, interaction_type, count, start_count, end_count, leadsource_id, website, first_deal_anniversary, has_acquisition, tax_record_sent, tax_record_sent_date, tax_record_start_date, tax_record_end_date, tags, withoutTags , tag_category, last_contact, lastContactSentDate, lastContactSentEdate, lastContactSentSdate, date_category, within_last_day, longer_than_day } = filters;

    if (company?.id !== "" && company?.id !== undefined) {
      params += `&company=${encodeURIComponent(company?.id)}`;
    }
    if (property !== "" && property !== undefined) {
      params += `&property=${encodeURIComponent(property)}`;
    }
    if (interaction_type !== "" && interaction_type !== undefined) {
      params += `&interaction_type=${encodeURIComponent(interaction_type)}`;
    }
    if (first_name !== "" && first_name !== undefined) {
      params += `&first_name=${encodeURIComponent(first_name)}`;
    }
    if (last_name !== "" && last_name !== undefined) {
      params += `&last_name=${encodeURIComponent(last_name)}`;
    }
    if (email !== "" && email !== undefined) {
      params += `&email=${encodeURIComponent(email)}`;
    }
    if (phone !== "" && phone !== undefined) {
      params += `&phone=${encodeURIComponent(phone)}`;
    }
    if (street_address !== "" && street_address !== undefined) {
      params += `&street_address=${encodeURIComponent(street_address)}`;
    }
    if (city !== "" && city !== undefined) {
      params += `&city=${encodeURIComponent(city)}`;
    }
    if (state !== "" && state !== undefined) {
      params += `&state=${encodeURIComponent(state)}`;
    }
    if (zip_code !== "" && zip_code !== undefined) {
      params += `&zip_code=${encodeURIComponent(zip_code)}`;
    }
    if (interaction_type_user !== "" && interaction_type_user !== undefined) {
      params += `&interaction_type_user=${encodeURIComponent(interaction_type_user)}`;
    }
    if (leadsource_id !== "" && leadsource_id !== undefined) {
      params += `&leadsource_id=${encodeURIComponent(leadsource_id)}`;
    }
    if (website !== undefined) {
      params += `&website=${encodeURIComponent(website)}`;
    }
    if (first_deal_anniversary !== undefined) {
      params += `&first_deal_anniversary=${encodeURIComponent(first_deal_anniversary)}`;
    }
    if (has_acquisition !== undefined) {
      params += `&has_acquisition=${encodeURIComponent(has_acquisition)}`;
    }
    if (tax_record_sent !== undefined) {
      params += `&tax_record_sent=${encodeURIComponent(tax_record_sent)}`;
    }
    if (tax_record_sent_date !== "" && tax_record_sent_date !== undefined) {
      params += `&tax_record_sent_date=${encodeURIComponent(tax_record_sent_date)}`;
    }
    if (tax_record_start_date !== "" && tax_record_start_date !== undefined) {
      params += `&tax_record_start_date=${encodeURIComponent(tax_record_start_date)}`;
    }
    if (tax_record_end_date !== "" && tax_record_end_date !== undefined) {
      params += `&tax_record_end_date=${encodeURIComponent(tax_record_end_date)}`;
    }
    if (tag_category && tags.length > 0) {
      params += `&tag_category=${encodeURIComponent(tag_category)}`;
    }
    if (withoutTags !== undefined && withoutTags.length > 0) {
      params += `&withoutTags=${encodeURIComponent(withoutTags.join(","))}`;
    }
    if (tags.length > 0) {
      params += `&tags=${encodeURIComponent(tags.join(","))}`;
    }
    if ((last_contact && lastContactSentDate !== "" && lastContactSentDate !== undefined) ||
        (last_contact && lastContactSentSdate !== "" && lastContactSentEdate !== "" &&
         lastContactSentSdate !== undefined && lastContactSentEdate !== undefined)) {
      params += `&last_contact=${encodeURIComponent(last_contact)}`;
    }
    if (lastContactSentDate !== "" && lastContactSentDate !== undefined) {
      params += `&last_update_date=${encodeURIComponent(lastContactSentDate)}`;
    }
    if (lastContactSentSdate !== "" && lastContactSentSdate !== undefined) {
      params += `&last_update_start_date=${encodeURIComponent(lastContactSentSdate)}`;
    }
    if (lastContactSentEdate !== "" && lastContactSentEdate !== undefined) {
      params += `&last_update_end_date=${encodeURIComponent(lastContactSentEdate)}`;
    }
    if (date_category !== undefined) {
      params += `&date_category=${encodeURIComponent(date_category)}`;
    }
    if (within_last_day !== "" && within_last_day !== undefined) {
      params += `&within_last_day=${encodeURIComponent(within_last_day)}`;
    }
    if (longer_than_day !== "" && longer_than_day !== undefined) {
      params += `&longer_than_day=${encodeURIComponent(longer_than_day)}`;
    }
    if (count !== "" && count !== undefined) {
      params += `&count=${encodeURIComponent(count)}`;
    }
    if (start_count !== "" && start_count !== undefined) {
      params += `&start_count=${encodeURIComponent(start_count)}`;
    }
    if (end_count !== "" && end_count !== undefined) {
      params += `&end_count=${encodeURIComponent(end_count)}`;
    }
    if (sortDirection && sortBy !== "id") {
      params += `&sort_direction=${sortDirection}`;
    }
    if (sortBy !== "id" && sortBy !== undefined) {
      params += `&sort_by=${sortBy}`;
    }
    
   localStorage.setItem("queryParams", params);

    return params;
  };

  const handleSelectCheck = (item) => {
    const isSelected = selectedItem.some((el) => el.id === item.id);
  
    if (isSelected) {
      setSelectedItem(selectedItem.filter((el) => el.id !== item.id));
    } else {
      setSelectedItem([...selectedItem, item]);
    }
  };
  


  useEffect(() => {
    if (selectedItem.length === contactAllIds.length) {
      setIsSelectAll(true);
    } else {
      setIsSelectAll(false);
    }
  }, [selectedItem, contactAllIds]);

  const fetchContacts = (filters) => {
    const queryParams = buildQueryParams(filters);
    axios
      .get(`${BASE_URL}/contact-list?${queryParams}`, config)
      .then((res) => {
        const value = res?.data?.data?.contacts;
        setContactsData(value?.data || []);
        setPaginationData({
          totalItems: value?.total ?? 0,
          from: value?.from ?? 0,
          to: value?.to ?? 0,
          totalPages: value?.last_page ?? 0,
        });
        setSelectedItem([]);
        setIsSelectAll(false);
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleNext = () => {
    if (currentPage !== paginationData.totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const onSuccess = (filters) => {
    setLoading(true);
    if (filters) {
      fetchContacts(filters);
    } else {
      fetchContacts(filterData);
    }
  };

  useEffect(() => {
    fetchContacts(filterData);
  }, [sortBy, sortDirection]);

  useEffect(() => {
    onSuccess();
  }, [currentPage, itemPerPage, search]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams) {
      queryParams.set('search', search);
    } else {
      queryParams.delete('search'); 
    }
    navigate({ search: queryParams.toString() }, { replace: true });
  }, [search, navigate, location.search]);

  const handleInputChange = (e) => {
    setSearch(e.target.value);
    queryParams.set('search', e.target.value);
  };



  const loadRingCentralScript = () => {
    return new Promise((resolve, reject) => {
      if (document.getElementById('rc-embeddable-script')) {
        resolve(); // Script already loaded
        return;
      }
  
      const script = document.createElement('script');
      const clientId = "VXh8hvv1uEjfhbIh4G3MSV"; // Replace with your actual Client ID
      const appServer = "https://platform.ringcentral.com"; // Production
      const appSecret = 'XtuWDyDsPcnfhi8iFqk7Pv5vU6VrPrnT1fDIiwdeA42d'; // Replace with your App Secret
      const redirectUri = encodeURIComponent('https://apps.ringcentral.com/integration/ringcentral-embeddable/latest/redirect.html'); // Replace with your redirect URI
      script.id = 'rc-embeddable-script';
      script.src = `https://apps.ringcentral.com/integration/ringcentral-embeddable/latest/adapter.js?clientId=${clientId}&appServer=${appServer}&appSecret=${appSecret}&redirectUri=${redirectUri}`; // Removed redirect URI from here
      script.onload = resolve; // Resolve when script loads successfully
      script.onerror = () => reject(new Error('Failed to load RingCentral Embeddable script'));
  
      document.body.appendChild(script);
    });
  };
  
  const initializeWidget = () => {
    const existingWidget = document.getElementById('rc-widget');
    if (existingWidget) {
      existingWidget.style.display = 'none';
      return;
    }
  
    const widgetContainer = document.createElement('div');
    widgetContainer.id = 'rc-widget';
    widgetContainer.style.display = 'none';
    document.body.appendChild(widgetContainer);
  
    if (window.RingCentralEmbeddable && typeof window.RingCentralEmbeddable.createWidget === 'function') {
      window.RingCentralEmbeddable.createWidget({
        appKey: 'VXh8hvv1uEjfhbIh4G3MSV',
        server: 'https://platform.ringcentral.com',
        domElement: widgetContainer,
      });
    } else {
      console.error('RingCentralEmbeddable.createWidget is not available');
    }
  };
  
  const waitForIframeAndSendCall = (phoneNumber) => {
    const iframe = document.querySelector('iframe');
    if (iframe) {
      iframe.contentWindow.postMessage(
        {
          type: 'rc-adapter-new-call',
          phoneNumber: phoneNumber,
          callWithRingCentral: true,
        },
        '*'
      );
    } else {
      setTimeout(() => waitForIframeAndSendCall(phoneNumber), 500); // Retry after 500ms
    }
  };
  
  const placeCall = (phoneNumber) => {
    const rcWidget = document.getElementById('rc-widget');
  
    if (!rcWidget) {
      loadRingCentralScript()
        .then(() => {
          initializeWidget();
          setTimeout(() => {
            const widget = document.getElementById('rc-widget');
            if (widget) {
              widget.style.display = 'block'; 
            }
            waitForIframeAndSendCall(phoneNumber); 
          }, 2000); 
        })
        .catch((error) => console.error(error.message));
    } else {
      rcWidget.style.display = 'block'; 
      waitForIframeAndSendCall(phoneNumber);
    }
  };
  
  const closeWidget = () => {
    const widget = document.getElementById('rc-widget');
    if (widget) {
      widget.style.display = 'none';
    }
  };
  

  return (
    <BaseLayout>
      <div className="contracts_only">
        <div className="contact-header">
          <p className="head-1 dark-H">
            Contacts
            {selectedItem?.length > 0 && (
              <span className="body-L ml-2">
                ({selectedItem?.length} <span className="dark-H">{selectedItem?.length === 1 ? "item" : "items"} selected</span>)
              </span>
            )}
          </p>
          <div className="md:flex block gap-4 items-center">
            <div className="flex gap-4 items-center">
              <ContactFilter
                filterData={filterData}
                onSetFilterData={(value) => {
                  setFilterData(value);
                }}
                onCallApiAgain={(filters) => onSuccess(filters)}
              />

              <ImportExport
                from="contact"
                onCallApi={() => {
                  onSuccess();
                  setSelectedItem([]);
                }}
              />
            </div>

            <div className="flex gap-4 justify-between items-center">
              <div className="search-box contacts">
                <input
                  type="text"
                  className="body-S"
                  placeholder="search here..."
                  value={ queryParams.get('search') }
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setSearch(newValue.startsWith(" ") ? newValue.trimStart() : newValue);
                  }}
                  style={{ border: "1px solid #D8D8D8", marginTop: "0px" }}
                />
              </div>
              <button
                className="add-contact-button green-bg-H light-L body-S"
                onClick={() => {
                  setShowModal(true);
                }}
              >
                <img className="mr-2 sidebar-icons" src={Plus} alt="plus" /> Add
              </button>
            </div>
          </div>
        </div>

        <div className="light-bg-L pb-3 table-container">
          <div className={`${isSidebarCollapsed ? "collapsed-width" : "width"} table-info light-bg-L`}>
            {loading ? (
              <Loader />
            ) : contactsData?.length === 0 ? (
              <p className="body-N text-center mt-6 p-10">No Contact Found</p>
            ) : (
              <table className="contact-table two-rows-static light-bg-L">
                <thead>
                  <tr className="uppercase body-N dark-M">
                    <th>
                    <label className="container">
                      <input
                        type="checkbox"
                        checked={selectedItem.length > 0 && selectedItem.length === contactsData.length}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          setIsSelectAll(isChecked);
                  
                          if (isChecked) {
                            setSelectedItem([...contactsData]);
                          } else {
                            setSelectedItem([]);
                          }
                        }}
                      />
                      <span className="checkmark"></span>
                    </label>
                    </th>
                    <th className="green-H">
                      <div className="table-header">
                        Contact Name
                        <img
                          role="button"
                          src={sortBy !== "first_name" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                          onClick={() => {
                            setSortBy("first_name");
                            if (sortDirection === "desc") {
                              setSortDirection("asc");
                            } else {
                              setSortDirection("desc");
                            }
                          }}
                          alt="icon"
                        />
                      </div>
                    </th>
                    <th>
                      <div className="table-header">
                        Company Name
                        <img
                          role="button"
                          src={sortBy !== "company_name" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                          onClick={() => {
                            setSortBy("company_name");
                            if (sortDirection === "desc") {
                              setSortDirection("asc");
                            } else {
                              setSortDirection("desc");
                            }
                          }}
                          alt="icon"
                        />
                      </div>
                    </th>
                    <th>
                      <div className="table-header">
                        Last Contacted
                        <img
                          role="button"
                          src={sortBy !== "updated_at" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                          alt="icon"
                          onClick={() => {
                            setSortBy("updated_at");
                            if (sortDirection === "desc") {
                              setSortDirection("asc");
                            } else {
                              setSortDirection("desc");
                            }
                          }}
                        />
                      </div>
                    </th>
                    <th>
                      <div className="table-header">
                        PRIMARY PHONE
                        <img
                          role="button"
                          src={sortBy !== "phone" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                          onClick={() => {
                            setSortBy("phone");
                            if (sortDirection === "desc") {
                              setSortDirection("asc");
                            } else {
                              setSortDirection("desc");
                            }
                          }}
                          alt="icon"
                        />
                      </div>
                    </th>
                    <th>
                      <div className="table-header">
                        PRIMARY EMAIL
                        <img
                          role="button"
                          src={sortBy !== "email" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                          onClick={() => {
                            setSortBy("email");
                            if (sortDirection === "desc") {
                              setSortDirection("asc");
                            } else {
                              setSortDirection("desc");
                            }
                          }}
                          alt="icon"
                        />
                      </div>
                    </th>
                    <th>
                      <div className="table-header">
                        Tags
                        <img
                          role="button"
                          src={sortBy !== "tags" ? swap : sortDirection === "desc" ? sortDesc : sortAsce}
                          onClick={() => {
                            setSortBy("tags");
                            if (sortDirection === "desc") {
                              setSortDirection("asc");
                            } else {
                              setSortDirection("desc");
                            }
                          }}
                          alt="icon"
                        />
                      </div>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {Array.isArray(contactsData) &&
                    contactsData.map((el, idx) => (
                      <tr key={idx} className="dark-H body-N">
                        <td className="">
                        <label className="container">
                          <input
                            type="checkbox"
                            checked={selectedItem.some((item) => item.id === el.id)} 
                            onChange={() => handleSelectCheck(el)}
                          />
                          <span className="checkmark"></span>
                        </label>

                        </td>
                        <td className="head-5 normal-case">
                         <Link
                           to={`/contact/${el.id}?search=${search}`}
                           rel="noopener noreferrer"
                           onClick={() => {navigate(`/contact/${el.id}`);}}
                         >
                           {el.first_name} {el.last_name}
                         </Link>
                       </td>


                       <td className="green-H head-5">
                        <Link
                          to={`/company/${el.company_id}`}
                          onClick={(e) => {
                            e.preventDefault(); 
                            if (el?.company_id) {
                              navigate(`/company/${el.company_id}`); 
                            }
                          }}
                          rel="noopener noreferrer"
                        >
                          {el?.company?.company_name}
                        </Link>
                      </td>


                        <td>
                          {el?.get_latest_activity?.date ? moment(el?.get_latest_activity?.date).tz('America/New_York').format("MM/DD/YY") : ''} <span className="dark-M body-S">{el?.get_latest_activity?.date_connected ? ` (${moment(el.get_latest_activity.date_connected).fromNow()})`: ''}</span>
                        </td>
                        
                        <td className="flex items-center gap-2">
                          {el.phone?.[0]?.phone_number ? (
                            <>
                              <img
                                role="button"
                                src={phone}
                                alt="icon"
                                className="sidebar-icons"
                                onClick={() => {
                                  const phone = el.phone[0]?.phone_number; 
                                  placeCall(phone); 
                                }}
                              />
                              <span>{formatPhoneNumber(el.phone[0]?.phone_number)}</span>
                            </>
                          ) : (
                            ''
                          )}
                        </td>

                        <td>{el.email?.[0]?.email_id}</td>
                        <td className="flex flex-wrap items-center gap-2" style={{ width: "350px" }}>
                          {el?.tags?.map((tag, i) => (
                            <p key={i} className="tags green-H body-S">
                              {tag?.tag_name ? tag?.tag_name : tag}
                            </p>
                          ))}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
          </div>
          {contactsData.length !== 0 && (
            <ContactPagination
              from="contact"
              setSelectedItem={setSelectedItem}
              selectedItem={selectedItem}
              paginationData={paginationData}
              handleNext={handleNext}
              handlePrev={handlePrev}
              tags={tags}
              setTags={setTags}
              onSuccess={onSuccess}
              itemPerPage={itemPerPage}
              handleItemPerPageSelect={(value) => {
                setItemPerPage(value);
                setCurrentPage(1);
              }}
            />
          )}
        </div>

        <AddContact
          showModal={showModal}
          onClose={() => {
            setShowModal(false);
          }}
          onCallApi={onSuccess}
        />

         <PhoneStatusModal phone={selectedPhone} onClick={closeWidget} showModal={showPhoneModal} onClose={() => setShowPhoneModal(false)} />
      </div>
    </BaseLayout>
  );
};

export default Contacts;

