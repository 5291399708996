import React, { useEffect, useRef, useState } from "react";
import { Radio, RadioGroup, Stack } from "@chakra-ui/react";
import { NotificationManager } from "react-notifications";
import axios from "axios";
import useAuth from "../../../hooks/useAuth";
import { BASE_URL } from "../../../utils/Element";
import sqft from "../../../assets/icons/sq ft.svg";
import acres from "../../../assets/svgs/acres.svg";
import dollor from "../../../assets/icons/dollar.svg";
import { formatPhoneNumber } from "../../../utils/utils";
import { activityOptions, filterThreeTabData } from "../../../utils/options";
import percentage from "../../../assets/icons/percentage.svg";
import InputWithIcon from "../../../components/InputWithIcon";
import SearchDropdown from "../../../components/Dropdowns/SearchDropdown";
import FilterTabData from "../../../components/FilterComponents/FilterTabData";
import OptionalOption from "../../../components/FilterComponents/OptionalOption";
import FilterFieldHeader from "../../../components/FilterComponents/FilterFieldHeader";
import FilterSearchSection from "../../../components/FilterComponents/FilterSearchSection";
import CheckboxSearchOptions from "../../../components/FilterComponents/CheckboxSearchOptions";
import { stateOptions } from "../../../utils/options";
import { IoIosArrowDown as ArrowDown } from "react-icons/io";
import Select from "react-select";
import {  filterTabData } from "../../../utils/options";


const PropertyFilterData = ({ filterData, onSetFilterData, isSidebarOpen ,onSetAcquisitionsData,onSetError , error, acquisitionData, }) => {
  const [config] = useAuth();
   const selectRef = useRef(null);
  const [ownerOptions, setOwnerOptions] = useState([]);
  const [contactTagOptions, setContactTagOptions] = useState([]);
  const [propertyTagOptions, setPropertyTagOptions] = useState([]);
  const dropdownRef = useRef(null);
  const [propertyTypeOptions, setPropertyTypeOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [userOptions , setUserOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchSuggestions = (inputValue) => {
    const type = filterData?.ownerType === "company" ? "companies" : "contacts";
    const encodedInputValue = encodeURIComponent(inputValue); // Encode special characters
    axios
      .get(`${BASE_URL}/get-datalisting?list=${type}&search=${encodedInputValue}`, config)
      .then((res) => {
        const data = res.data;
        const options = filterData?.ownerType === "company" ? data.data.company_list.map((company) => ({ value: company.id, label: company.company_name })) : data.data.contact_list.map((contact) => ({ value: contact.id, label: `${contact.first_name} ${contact.last_name}` }));
        setOwnerOptions(options);
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      });
  };

  const fetchPropertyTypes = () => {
    axios
      .get(`${BASE_URL}/get-datalisting?list=property_types`, config)
      .then((res) => {
        const value = res?.data?.data?.property_types;
        setPropertyTypeOptions(value?.map((el) => ({ value: el?.id, label: el?.type })));
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      });
  };

  const fetchPropertyTags = () => {
    axios
      .get(`${BASE_URL}/get-datalisting?list=property_tag`, config)
      .then((res) => {
        const value = res?.data?.data?.property_tag;
        setPropertyTagOptions(value?.map((el) => ({ value: el?.id, label: el?.tag_name })));
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      });
  };

  const fetchContactTags = () => {
    axios
      .get(`${BASE_URL}/get-datalisting?list=owner_tags`, config)
      .then((res) => {
        const value = res?.data?.data?.contact_tags;
        setContactTagOptions(value?.map((el) => ({ value: el?.id, label: el?.tag_name })));
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      });
  };

  const fetchUsers = () => {
    setLoading(true);
    axios
      .get(`${BASE_URL}/get-datalisting?list=users`, config)
      .then((res) => {
        const data = res?.data?.data;
        const options = data?.map((el) => ({ value: el?.id, label: `${el?.first_name} ${el?.last_name}`, role: el?.role_id }));
        setUserOptions(options);
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      })
      .finally(() => setLoading(false));
  };



  useEffect(() => {
    if (isSidebarOpen) {
      fetchPropertyTypes();
      fetchContactTags();
      fetchPropertyTags();
      fetchUsers();
    }
  }, [isSidebarOpen]);

  const handlePropertyHistory = (value, key) => {
    onSetFilterData({ ...filterData, [key]: value });
  };

  const handleHasOwner = (value) => {
    if (value === "no") {
      onSetFilterData({ ...filterData, hasOwner: "no", ownerPhoneCheck: "", ownerPhone: "", ownerStreetAddressCheck: "", ownerStreetAddress: "", ownerTaxRecordCheck: "", taxRecordSentDate: "", taxRecordSentStartDate: "", taxRecordSentEndDate: "" });
    } else {
      handleFilterChange(value, "hasOwner");
    }
  };

  const handleOwnerPhoneCheck = (value) => {
    if (value === "no") {
      onSetFilterData({ ...filterData, ownerPhoneCheck: "no", ownerPhone: "" });
    } else {
      handleFilterChange(value, "ownerPhoneCheck");
    }
  };

  const handlerOwnerStreetAddressCheck = (value) => {
    if (value === "no") {
      onSetFilterData({ ...filterData, ownerStreetAddressCheck: "no", ownerStreetAddress: "" });
    } else {
      handleFilterChange(value, "ownerStreetAddressCheck");
    }
  };

  const handleOwnerTaxRecordCheck = (value) => {
    if (value === "no") {
      onSetFilterData({ ...filterData, ownerTaxRecordCheck: "no", taxRecordSentDate: "", taxRecordSentStartDate: "", taxRecordSentEndDate: "" });
    } else {
      handleFilterChange(value, "ownerTaxRecordCheck");
    }
  };

  const handleClearUpdate = () => {
    onSetFilterData({ ...filterData, sentDate: "", lastUpdateOption: "date" });
  };

 


  const handleClearSold = () => {
    onSetFilterData({ ...filterData, lastSoldSentDate: "", lastSoldOption: "date" });
  };

  const handleClearAskingCapRate = () => {
    onSetFilterData({ ...filterData, minCaprate: "", maxCaprate: "" });
  };

  const handleSoldCapRateClear = () => {
    onSetFilterData({ ...filterData, min_sold_cap_rate: "", max_sold_cap_rate: "" });
  };

   const handleSoldPriceClear = () =>{
    onSetFilterData({...filterData , min_sold_price:"" , max_sold_price : ""});
   }
  const handleClearAskingPrice = () => {
    onSetFilterData({ ...filterData, minPrice: "", maxPrice: "" });
  };

  const handleClearBuildingSize = () => {
    onSetFilterData({ ...filterData, minBuilding: "", maxBuilding: "" });
  };

  const handleClearLandSize = () => {
    onSetFilterData({ ...filterData, minLand: "", maxLand: "" });
  };

  const lastUpdateHandler = () => {
    onSetFilterData({ ...filterData, last_contact: "date", lastContactSentDate: "" });
  };

  const handleClearVacanacy = () => {
    onSetFilterData({ ...filterData, minVacancy: "", maxVacancy: "" });
  };

  const handleFilterChange = (value, name) => {
    let nameVal = name;
    if(name === 'owner_tags' && filterData?.tag_category === 'without'){
        nameVal = 'owner_without_tags'
    }
   if (name === 'tags' && filterData?.tag_category === 'without') {
    nameVal = 'without_property_tags';
  }
    onSetFilterData({ ...filterData, [nameVal]: value });
  };

  return (
    <div className="">
      <FilterSearchSection
        type="text"
        title={"Property Name"}
        placeholder={"write name here"}
        value={filterData?.propertyName}
        onChange={(e) => {
          handleFilterChange(e.target.value, "propertyName");
        }}
      />

      <hr className="my-6" style={{ borderColor: "#6F6F6F" }} />

      <FilterSearchSection
        type="text"
        title={"Store ID"}
        placeholder={"write store number"}
        value={filterData?.storeId}
        onChange={(e) => {
          handleFilterChange(e.target.value, "storeId");
        }}
      />

      <hr className="my-6" style={{ borderColor: "#6F6F6F" }} />

      <div>
      <FilterFieldHeader
        title={"Property Type"}
        type="array"
        data={filterData?.propertyType?.length || 0}
        handleCross={() => {
          handleFilterChange([], "propertyType");
        }}
      />


        <CheckboxSearchOptions
          handleChange={(value) => {
            handleFilterChange(value, "propertyType");
          }}
          selectedOptions={filterData?.propertyType}
          options={propertyTypeOptions}
        />
      </div>

      <hr className="my-6" style={{ borderColor: "#6F6F6F" }} />

      <div className="mt-8">
        <FilterTabData 
         title={"Property Tags"} 
         tabData={filterTabData}
         activeTab={filterData?.tag_category}
         onSetActiveTab={(value) => {
          handleFilterChange(value, "tag_category");
        }} />

        <CheckboxSearchOptions
          handleChange={(value) => {
            handleFilterChange(value, "tags");
          }}
          selectedOptions={filterData?.tag_category === 'with'? filterData?.tags : filterData?.without_property_tags}
          options={propertyTagOptions}
        />
      </div>

      <hr className="my-6" style={{ borderColor: "#6F6F6F" }} />

      <div>
        <FilterFieldHeader
          title={"Address"}
          type="string"
          data={filterData?.streetAddress}
          handleCross={() => {
            handleFilterChange("", "streetAddress");
          }}
        />

        <div className="search-box w-[100%] mt-4">
          <input
            type="text"
            className="body-N"
            placeholder="write street address here"
            style={{ width: "100%" }}
            value={filterData?.streetAddress}
            onChange={(e) => {
              handleFilterChange(e.target.value, "streetAddress");
            }}
          />
        </div>

        <div className="search-box w-[100%] mt-5">
          <Select
              isMulti
              className="body-N"
              placeholder="State"
              options={stateOptions}
              value={stateOptions?.filter((el) => filterData?.state?.includes(el.value))}
              onChange={(options) => {
                const values = options?.map((el) => el?.value);
                if (values?.[values?.length - 1] === "Nationwide") {
                  onSetAcquisitionsData({ ...filterData, state: ["Nationwide"] });
                } else {
                  onSetFilterData({ ...filterData, state: values?.filter((el) => el !== "Nationwide") });
                }
              }}
            />
            {error?.state && <span className="body-S red-D">{error?.state}</span>}
          </div>
        
        <div className="flex flex-row gap-4">
          <div className="search-box w-[50%] mt-4">
            <input
              type="text"
              className="body-N"
              placeholder="city"
              style={{ width: "100%" }}
              value={filterData?.city}
              onChange={(e) => {
                handleFilterChange(e.target.value, "city");
              }}
            />
          </div>

           <div className="search-box w-[50%] mt-4">
            <input
              type="number"
              min={0}
              className="body-N"
              placeholder="zip code"
              style={{ width: "100%" }}
              value={filterData?.zip}
              onChange={(e) => {
                handleFilterChange(e.target.value, "zip");
              }}
            />
          </div>
        </div>
      </div>

      <hr className="my-6" style={{ borderColor: "#6F6F6F" }} />

      <FilterTabData
        title={"Last Update"}
        tabData={filterThreeTabData}
        activeTab={filterData?.lastUpdateOption}
        lastDate={filterData?.sentDate}
        handleLastUpdate={handleClearUpdate}
        onSetActiveTab={(value) => {
          onSetFilterData({ ...filterData, lastUpdateOption: value, sentDate: "", lastUpStartDate: "", lastUpEndDate: "", lastUpDateCategory: "days", withinLastDay: "", longerThanDay: "" });
        }}
      />

      <OptionalOption
        activeTab={filterData?.lastUpdateOption}
        onSetLastDate={(value) => {
          handleFilterChange(value, "sentDate");
        }}
        date={filterData?.sentDate}
        onSetDateRange1={(value) => handleFilterChange(value, "lastUpStartDate")}
        date2={filterData?.lastUpStartDate}
        onSetDateRange2={(value) => handleFilterChange(value, "lastUpEndDate")}
        date3={filterData?.lastUpEndDate}
        category={filterData?.lastUpDateCategory}
        onSetCategory={(value) => handleFilterChange(value, "lastUpDateCategory")}
        lastUp_days={filterData?.lastUpdateOption === "within_last" ? filterData?.withinLastDay : filterData?.lastUpdateOption === "longer_than" ? filterData?.longerThanDay : ""}
        onSetDays={(value) => {
          if (filterData?.lastUpdateOption === "within_last") {
            onSetFilterData({ ...filterData, withinLastDay: value, longerThanDay: "" });
          } else if (filterData?.lastUpdateOption === "longer_than") {
            onSetFilterData({ ...filterData, withinLastDay: "", longerThanDay: value });
          } else {
            onSetFilterData({ ...filterData, withinLastDay: "", longerThanDay: "" });
          }
        }}
        onClear={() => {
          onSetFilterData({ ...filterData, sentDate: "", lastUpStartDate: "", lastUpEndDate: "" });
        }}
      />

      <hr className="my-6" style={{ borderColor: "#6F6F6F" }} />

      <div>
        <FilterFieldHeader title={"Asking Cap Rate"} data={filterData?.minCaprate} type="to" symbol={"  to  "} data2={filterData?.maxCaprate} handleCross={handleClearAskingCapRate} />

        <div className="flex flex-row mt-4">
          <div className="w-[50%]">
            <InputWithIcon
              icon={percentage}
              type="number"
              min={0}
              placeholder="min rate"
              value={filterData?.minCaprate}
              onChange={(e) => {
                handleFilterChange(e.target.value, "minCaprate");
              }}
            />
          </div>
          <div className="w-[50%]">
            <InputWithIcon
              icon={percentage}
              type="number"
              min={0}
              placeholder="max rate"
              value={filterData?.maxCaprate}
              onChange={(e) => {
                handleFilterChange(e.target.value, "maxCaprate");
              }}
            />
          </div>
        </div>
      </div>

      <hr className="my-6" style={{ borderColor: "#6F6F6F" }} />

      <div>
        <FilterFieldHeader title={"Asking Price"} data={filterData?.minPrice} type="to" symbol={"  to  "} data2={filterData?.maxPrice} handleCross={handleClearAskingPrice} />

        <div className="flex flex-row mt-4">
          <div className="w-[50%]">
            <InputWithIcon
              icon={dollor}
              type="number"
              min={0}
              placeholder="min price"
              value={filterData?.minPrice}
              onChange={(e) => {
                handleFilterChange(e.target.value, "minPrice");
              }}
            />
          </div>
          <div className="w-[50%]">
            <InputWithIcon
              icon={dollor}
              type="number"
              min={0}
              placeholder="max price"
              value={filterData?.maxPrice}
              onChange={(e) => {
                handleFilterChange(e.target.value, "maxPrice");
              }}
            />
          </div>
        </div>
      </div>

      <hr className="my-6" style={{ borderColor: "#6F6F6F" }} />

      <div>
        <FilterFieldHeader title={"Building Size"} data={filterData?.minBuilding} type="to" symbol={"  to  "} data2={filterData?.maxBuilding} handleCross={handleClearBuildingSize} />

        <div className="flex flex-row mt-4">
          <div className="w-[50%]">
            <InputWithIcon
              icon={sqft}
              type="number"
              min={0}
              placeholder="min size"
              value={filterData?.minBuilding}
              onChange={(e) => {
                handleFilterChange(e.target.value, "minBuilding");
              }}
            />
          </div>
          <div className="w-[50%]">
            <InputWithIcon
              icon={sqft}
              type="number"
              min={0}
              placeholder="max size"
              value={filterData?.maxBuilding}
              onChange={(e) => {
                handleFilterChange(e.target.value, "maxBuilding");
              }}
            />
          </div>
        </div>
      </div>

      <hr className="my-6" style={{ borderColor: "#6F6F6F" }} />

      <div>
        <FilterFieldHeader title={"Land Size"} data={filterData?.minLand} type="to" symbol={"  to  "} data2={filterData?.maxLand} handleCross={handleClearLandSize} />

        <div className="flex mt-4">
          <div className="w-[50%]">
            <InputWithIcon
              icon={acres}
              type="number"
              min={0}
              placeholder="min size"
              value={filterData?.minLand}
              onChange={(e) => {
                handleFilterChange(e.target.value, "minLand");
              }}
            />
          </div>
          <div className="w-[50%]">
            <InputWithIcon
              icon={acres}
              type="number"
              min={0}
              placeholder="max size"
              value={filterData?.maxLand}
              onChange={(e) => {
                handleFilterChange(e.target.value, "maxLand");
              }}
            />
          </div>
        </div>
      </div>

      <hr className="my-6" style={{ borderColor: "#6F6F6F" }} />

     <p className="head-4 dark-H mb-4">Property History</p>

      <div>
        <FilterFieldHeader  
         title={
          <span className="body-N">
            {"Sold Cap Rate"}
          </span>
        }
         data={filterData?.min_sold_cap_rate} 
         type="to" 
         symbol={"  to  "} 
         data2={filterData?.max_sold_cap_rate} 
         handleCross={handleSoldCapRateClear} />

        <div className="flex flex-row mt-4">
          <div className="w-[50%]">
            <InputWithIcon
              icon={dollor}
              type="number"
              min={0}
              placeholder="min sold cap rate"
              value={filterData?.min_sold_cap_rate}
              onChange={(e) => {
                handleFilterChange(e.target.value, "min_sold_cap_rate");
              }}
            />
          </div>
          <div className="w-[50%]">
            <InputWithIcon
              icon={dollor}
              type="number"
              min={0}
              placeholder="max sold cap rate"
              value={filterData?.max_sold_cap_rate}
              onChange={(e) => {
                handleFilterChange(e.target.value, "max_sold_cap_rate");
              }}
            />
          </div>
        </div>
      </div>

      <div className="mt-5">
        <FilterFieldHeader 
         title={<span className="body-N">
          {"Sold Price"}
         </span>
          }
         data={filterData?.min_sold_price} 
         type="to" 
         symbol={"  to  "} 
         data2={filterData?.max_sold_price} 
         handleCross={handleSoldPriceClear} />

        <div className="flex flex-row mt-4">
          <div className="w-[50%]">
            <InputWithIcon
              icon={dollor}
              type="number"
              min={0}
              placeholder="min sold price "
              value={filterData?.min_sold_price}
              onChange={(e) => {
                handleFilterChange(e.target.value, "min_sold_price");
              }}
            />
          </div>
          <div className="w-[50%]">
            <InputWithIcon
              icon={dollor}
              type="number"
              min={0}
              placeholder="max sold price"
              value={filterData?.max_sold_price}
              onChange={(e) => {
                handleFilterChange(e.target.value, "max_sold_price");
              }}
            />
          </div>
        </div>
      </div>

      <hr className="my-6" style={{ borderColor: "#6F6F6F" }} />

      <FilterTabData
        title={"Last Sold Date"}
        tabData={filterThreeTabData}
        activeTab={filterData?.lastSoldOption}
        lastDate={filterData?.lastSoldSentDate}
        handleLastUpdate={handleClearSold}
        onSetActiveTab={(value) => {
          onSetFilterData({ ...filterData, lastSoldOption: value, lastSoldSentDate: "", lastSoldStartDate: "", lastSoldEndDate: "", lastSoldCategory: "days", lastSoldWithinLastValue: "", lastSoldLongerThanValue: "" });
        }}
      />

               <OptionalOption
                 activeTab={filterData?.lastSoldOption} // Use lastSoldOption
                 onSetLastDate={(value) => {
                   handleFilterChange(value, "lastSoldSentDate");
                 }}
                 date={filterData?.lastSoldSentDate}
                 onSetDateRange1={(value) => handleFilterChange(value, "lastSoldStartDate")}
                 date2={filterData?.lastSoldStartDate}
                 onSetDateRange2={(value) => handleFilterChange(value, "lastSoldEndDate")}
                 date3={filterData?.lastSoldEndDate}
                 onSetCategory={(value) => handleFilterChange(value, "lastSoldCategory")}
                 category={filterData?.lastSoldCategory}
                 
                 // Use lastSoldOption to determine the correct days value
                 lastUp_days={
                   filterData?.lastSoldOption === "within_last"
                     ? filterData?.lastSoldWithinLastValue
                     : filterData?.lastSoldOption === "longer_than"
                     ? filterData?.lastSoldLongerThanValue
                     : ""
                 }
                 onSetDays={(value) => {
                   if (filterData?.lastSoldOption === "within_last") {
                     onSetFilterData({ ...filterData, lastSoldWithinLastValue: value, lastSoldLongerThanValue: "" });
                   } else if (filterData?.lastSoldOption === "longer_than") {
                     onSetFilterData({ ...filterData, lastSoldWithinLastValue: "", lastSoldLongerThanValue: value });
                   } else {
                     onSetFilterData({ ...filterData, lastSoldWithinLastValue: "", lastSoldLongerThanValue: "" });
                   }
                 }}
                 onClear={() => {
                   onSetFilterData({ ...filterData, lastSoldSentDate: "", lastSoldStartDate: "", lastSoldEndDate: "" });
                 }}
               />


      <hr className="my-6" style={{ borderColor: "#6F6F6F" }} />

      <div className="flex gap-5 justify-between items-center">
        <p className="head-4 dark-H">Owner</p>
        <RadioGroup
          onChange={(value) => {
            onSetFilterData({
              ...filterData,
              ownerType: value,
              owner_name: "",
              owner: "",
            });
          }}
          value={filterData?.ownerType}
        >
          <Stack direction="row" gap={5}>
            <Radio value="company">Company</Radio>
            <Radio value="contact">Contact</Radio>
          </Stack>
        </RadioGroup>
      </div>

      {filterData?.ownerType !== "" && (
        <div className="mt-4">
          <SearchDropdown
            isTop={false}
            placeholder={`Search ${filterData?.ownerType}`}
            text={filterData?.owner?.name}
            options={ownerOptions}
            fetchSuggestions={fetchSuggestions}
            onSetOptions={(value) => setOwnerOptions(value)}
            handleSelect={(option) => {
              onSetFilterData({
                ...filterData,
                owner: { id: option?.value, name: option?.label },
                owner_id: option?.value,
              });
              setOwnerOptions([]);
            }}
          />
        </div>
      )}

      <hr className="my-6" style={{ borderColor: "#6F6F6F" }} />

      <div className="flex gap-5 justify-between items-center">
        <p className="head-4 dark-H">Has Owner</p>
        <RadioGroup
          onChange={(value) => {
            handleHasOwner(value);
          }}
          value={filterData?.hasOwner}
        >
          <Stack direction="row" gap={5}>
            <Radio value="yes">Yes</Radio>
            <Radio value="no">No</Radio>
          </Stack>
        </RadioGroup>
      </div>

      {filterData?.hasOwner === "yes" && (
        <>
          <div>
            <div className="flex gap-5 justify-between items-center mt-4">
              <p className="head-4 dark-H">Has Owner Phone</p>
              <RadioGroup
                onChange={(value) => {
                  handleOwnerPhoneCheck(value);
                }}
                value={filterData?.ownerPhoneCheck}
              >
                <Stack direction="row" gap={5}>
                  <Radio value="yes">Yes</Radio>
                  <Radio value="no">No</Radio>
                </Stack>
              </RadioGroup>
            </div>
            {filterData?.ownerPhoneCheck === "yes" && (
              <input
                type="text"
                className="body-S mt-4"
                placeholder="Write owner phone here"
                value={formatPhoneNumber(filterData?.ownerPhone)}
                onChange={(e) => {
                  const value = e.target.value;
                  const cleaned = ("" + value)?.replace(/\D/g, "")?.slice(0, 10);
                  handleFilterChange(cleaned, "ownerPhone");
                }}
                onWheel={(e) => e.target.blur()}
              />
            )}
          </div>

          <div>
            <div className="flex gap-5 justify-between items-center mt-4">
              <p className="head-4 dark-H">Has Owner Street Address</p>
              <RadioGroup
                onChange={(value) => {
                  handlerOwnerStreetAddressCheck(value);
                }}
                value={filterData?.ownerStreetAddressCheck}
              >
                <Stack direction="row" gap={5}>
                  <Radio value="yes">Yes</Radio>
                  <Radio value="no">No</Radio>
                </Stack>
              </RadioGroup>
            </div>
            {filterData?.ownerStreetAddressCheck === "yes" && (
              <input
                type="text"
                className="body-S mt-4"
                placeholder="Write street address here"
                value={filterData?.ownerStreetAddress}
                onChange={(e) => {
                  handleFilterChange(e.target.value, "ownerStreetAddress");
                }}
              />
            )}
          </div>

          <div>
            <div className="flex gap-5 justify-between items-center mt-4">
              <p className="head-4 dark-H">Tax Record Letter Sent</p>
              <RadioGroup
                onChange={(value) => {
                  handleOwnerTaxRecordCheck(value);
                }}
                value={filterData?.ownerTaxRecordCheck}
              >
                <Stack direction="row" gap={5}>
                  <Radio value="yes">Yes</Radio>
                  <Radio value="no">No</Radio>
                </Stack>
              </RadioGroup>
            </div>

            {filterData?.ownerTaxRecordCheck === "yes" && (
              <OptionalOption
                activeTab="date"
                onSetLastDate={(value) => {
                  handleFilterChange(value, "taxRecordSentDate");
                }}
                date={filterData?.taxRecordSentDate}
                onSetDateRange1={(value) => handleFilterChange(value, "taxRecordSentStartDate")}
                date2={filterData?.taxRecordSentStartDate}
                onSetDateRange2={(value) => handleFilterChange(value, "taxRecordSentEndDate")}
                date3={filterData?.taxRecordSentEndDate}
                onClear={() => {
                  onSetFilterData({ ...filterData, taxRecordSentDate: "", taxRecordSentStartDate: "", taxRecordSentEndDate: "" });
                }}
              />
            )}
          </div>
        </>
      )}

      <hr className="my-6" style={{ borderColor: "#6F6F6F" }} />

      <div className="flex gap-5 justify-between items-center">
        <p className="head-4 dark-H">Owner Website</p>
        <RadioGroup
          onChange={(value) => {
            handleFilterChange(value, "ownerWebsite");
          }}
          value={filterData?.ownerWebsite}
        >
          <Stack direction="row" gap={5}>
            <Radio value="yes">Yes</Radio>
            <Radio value="no">No</Radio>
          </Stack>
        </RadioGroup>
      </div>

      {/* <hr className="my-6" style={{ borderColor: "#6F6F6F" }} />

      <div className="flex gap-5 justify-between items-center">
  
  <RadioGroup
    onChange={(value) => {
      handlePropertyHistory(value, "property_history");
    }}
    value={filterData?.property_history}
  >
    <Stack direction="row" gap={5}>
      <Radio value="yes">Yes</Radio>
      <Radio value="no">No</Radio>
    </Stack>
  </RadioGroup>
</div> */}

      <hr className="my-6" style={{ borderColor: "#6F6F6F" }} />

      <div>
        <FilterTabData
          title={"Last Contacted"}
          tabData={filterThreeTabData}
          activeTab={filterData?.last_contact}
          lastDate={filterData?.lastContactSentDate}
          handleLastUpdate={lastUpdateHandler}
          onSetActiveTab={(value) => {
            onSetFilterData({ ...filterData, last_contact: value, lastContactSentDate: "", lastContactSentSdate: "", lastContactSentEdate: "", lastcontactCategory: "days", within_last_contact_day: "", longer_than_contact_day: "" });
          }}
        />

        <OptionalOption
          activeTab={filterData?.last_contact}
          onSetLastDate={(value) => {
            handleFilterChange(value, "lastContactSentDate");
          }}
          date={filterData?.lastContactSentDate}
          onSetDateRange1={(value) => handleFilterChange(value, "lastContactSentSdate")}
          date2={filterData?.lastContactSentSdate}
          onSetDateRange2={(value) => handleFilterChange(value, "lastContactSentEdate")}
          date3={filterData?.lastContactSentEdate}
          category={filterData?.lastcontactCategory}
          onSetCategory={(value) => handleFilterChange(value, "lastcontactCategory")}
          lastUp_days={filterData?.last_contact === "within_last" ? filterData?.within_last_contact_day : filterData?.last_contact === "longer_than" ? filterData?.longer_than_contact_day : ""}
          onSetDays={(value) => {
            if (filterData?.last_contact === "within_last") {
              onSetFilterData({ ...filterData, within_last_contact_day: value, longer_than_contact_day: "" });
            } else if (filterData?.last_contact === "longer_than") {
              onSetFilterData({ ...filterData, within_last_contact_day: "", longer_than_contact_day: value });
            } else {
              onSetFilterData({ ...filterData, within_last_contact_day: "", longer_than_contact_day: "" });
            }
          }}
          onClear={() => {
            onSetFilterData({ ...filterData, lastContactSentDate: "", lastContactSentSdate: "", lastContactSentEdate: "" });
          }}
        />
      </div>

      <hr className="my-6" style={{ borderColor: "#6F6F6F" }} />

          <div className="mt-4">
                <p className="head-4 dark-H">Interaction Type</p>
        
                <div ref={dropdownRef} className="custom-dropdown mt-2">
                  <div
                    role="button"
                    className="select-header-input capitalize light-bg-L body-N dark-M flex justify-between items-center"
                    onClick={() => {
                      setIsOpen(!isOpen);
                    }}
                  >
               {filterData.interaction_type === "" 
                 ? "Select" 
                 : (activityOptions.find((el) => el.value === filterData.interaction_type)?.label || "select")
               } <ArrowDown />
                  </div>
                  {isOpen && (
                    <div className="dropdown-list-container light-bg-L dark-M body-N shadow rounded-box" style={{ width: "100%" }}>
                      <ul className="dropdown-list">
                        {activityOptions.map((el, i) => (
                          <li
                            key={i}
                            role="button"
                            onClick={() => {
                              handleFilterChange(el.value, "interaction_type");
                              setIsOpen(false);
                            }}
                            className={`${filterData.interaction_type === el.value ? "active" : ""}`}
                          >
                            {el.label}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
        </div>

       <hr className="my-6" style={{ borderColor: "#6F6F6F" }} />

       <div className="mt-4">
          <label className="dark-H head-4 mb-2">
           Interaction Type Users 
          </label>
          <Select
            ref={selectRef}
            className="body-N"
            options={userOptions}
            placeholder="Select"
            value={userOptions.find((option) => option?.value === filterData?.interaction_type_user) || null}
            onChange={(option) => handleFilterChange(option?.value, "interaction_type_user")}
          />
        </div>

        <hr className="my-6" style={{ borderColor: "#6F6F6F" }} />


        <div className="mt-3">
        <p className="head-4 dark-H">Own "X" or "X-Y" Amount of Properties</p>
        <OptionalOption
          activeTab={"date"}
          type="number"
          title="Range"
          placeholder="enter here"
          onSetLastDate={(value) => {
            handleFilterChange(value, "count");
          }}
          date={filterData?.count}
          onSetDateRange1={(value) => handleFilterChange(value, "start_count")}
          date2={filterData?.start_count}
          onSetDateRange2={(value) => handleFilterChange(value, "end_count")}
          date3={filterData?.end_count}
          onClear={() => {
            onSetFilterData({ ...filterData, count: "", start_count: "", end_count: "" });
          }}
        />
        </div>

    <hr className="my-6" style={{ borderColor: "#6F6F6F" }} />

      <div>
        <FilterTabData
          title={"Owner Tags"}
          tabData={filterTabData}
          activeTab={filterData?.tag_category}
          onSetActiveTab={(value) => {
            handleFilterChange(value, "tag_category");
          }}
        />

        <CheckboxSearchOptions
          handleChange={(value) => {
            handleFilterChange(value, "owner_tags");
          }}
          selectedOptions={filterData?.tag_category === 'with' ? filterData?.owner_tags : filterData?.owner_without_tags}
          options={contactTagOptions}
        />
      </div>

    </div>
  );
};

export default PropertyFilterData;
