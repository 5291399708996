import React, { useEffect, useState } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import PhoneField from "../../../components/AddDataComponents/PhoneField";
import AddressField from "../../../components/AddDataComponents/AddressField";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import { BASE_URL } from "../../../utils/Element";
import useAuth from "../../../hooks/useAuth";
import Select from "react-select";

const communicationData = [
  { label: "Bad #", value: "bad" },
  { label: "Do Not Blast", value: "do not blast" },
  { label: "Do Not Send", value: "do not send" },
];

const AddCompanyData = ({ addCompanyData, onSetAddCompanyData, handleSubmit, handleChange, handleClose, phones, onSetPhones, addresses, onSetAddresses, disable, error }) => {
  const [companyTagOptions, setCompanyTagOptions] = useState([]);
  const [config] = useAuth();
  const handleSelectCheck = (value) => {
    if (addCompanyData?.communication?.length === 0) {
      onSetAddCompanyData({ ...addCompanyData, communication: [value] });
    } else {
      const index = addCompanyData?.communication?.indexOf(value);
      if (index === -1) {
        onSetAddCompanyData({ ...addCompanyData, communication: [...addCompanyData?.communication, value] });
      } else {
        const filterData = addCompanyData?.communication?.filter((el) => el !== value);
        onSetAddCompanyData({ ...addCompanyData, communication: filterData });
      }
    }
  };

  const fetchCompanyTags = () => {
    axios
      .get(`${BASE_URL}/get-datalisting?list=company_tags`, config)
      .then((res) => {
        const value = res?.data?.data?.company_tags;
        setCompanyTagOptions(
          value?.map((el) => ({
            value: el?.tag_name,
            label: el?.tag_name,
          }))
        );
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      });
  };

  useEffect(() => {
    fetchCompanyTags();
    
  }, []);

  return (
    <div>
      <form className="contact-form" onSubmit={handleSubmit}>
        <div>
          <label className="dark-H head-4 mb-2">
            Company Name<span className="red-D">*</span>
          </label>
          <input
            className="body-N"
            name="companyName"
            type="text"
            placeholder="Write Company Name Here"
            value={addCompanyData.companyName}
            onChange={(e) => {
              onSetAddCompanyData({ ...addCompanyData, companyName: e.target.value });
            }}
          />
          {error?.companyname && <p className="body-S red-D pt-1">{error?.companyname}</p>}
        </div>

        <PhoneField phones={phones} onSetPhones={onSetPhones} />

        <AddressField isApt={false} addresses={addresses} onSetAddresses={onSetAddresses} />

        <div className="mt-6">
         <label className="dark-H head-4">Tags</label>
         <div className="mt-3">
           <Select
             isMulti
             options={companyTagOptions}
             value={companyTagOptions.filter((el) => addCompanyData?.communication?.includes(el.value))}
             onChange={(options) => {
              const values = options?.map((el) => el.value);
              handleChange(values, "communication");
            }}
             placeholder="Select tags"
             className="service-area body-N"
           />
         </div>
       </div>

        <div className="mt-6">
          <label className="dark-H head-4 mb-2">Website Link</label>
          <input
            className="body-N"
            name="websiteLink"
            type="text"
            placeholder="Write Website Link"
            value={addCompanyData.websiteLink}
            onChange={(e) => {
              handleChange(e.target.value, "websiteLink");
            }}
          />
        </div>

        <div className="mt-6">
          <label className="dark-H head-4 mb-2">Comments</label>
          <textarea
            rows={3}
            className="body-N"
            name="comment"
            type="text"
            placeholder="Write Comments Here..."
            value={addCompanyData.comment}
            onChange={(e) => {
              handleChange(e.target.value, "comment");
            }}
          />
        </div>

        <div className="mt-6">
          <button type="submit" disabled={disable} className="save-button light-L head-5 green-bg-H">
            Save
          </button>

          <button type="button" onClick={handleClose} className="green-H ml-5">
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddCompanyData;
