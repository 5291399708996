import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import useAuth from "../../../hooks/useAuth";
import { BASE_URL } from "../../../utils/Element";
import dollor from "../../../assets/icons/dollar.svg";
import { NotificationManager } from "react-notifications";
import { Radio, RadioGroup, Stack } from "@chakra-ui/react";
import percentage from "../../../assets/icons/percentage.svg";
import InputWithIcon from "../../../components/InputWithIcon";
import SearchDropdown from "../../../components/Dropdowns/SearchDropdown";
import { useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import Select from "react-select"; 
 


const AddOfferData = ({ showModal, formData, disable, error, onSetFormData, onSetError, handleSubmit, handleClose, from }) => {
  const [config] = useAuth();
   const { id } = useParams();
   const [loading, setLoading] = useState(false);
  const [sellerOptions, setSellerOptions] = useState([]);
  const selectRef = useRef(null);
  const [buyerOptions, setBuyerOptions] = useState([]);
  const [bdsOptions, setBdsOptions] = useState([]);
  const [bdsSellerOptions, setBdsSellerOptions] = useState([]);
  const [offerVisionOptions, setOfferVisionOptions] = useState([]);
  const [brBuyerOptions, setBrBuyerOptions] = useState([]);
  const [brSellerOptions, setBrSellerOptions] = useState([]);
  const [sellerData, setSellerData] = useState({ list_type: "",property_name: "",property_address: "",type_id: "",asking_cap_rate: "",asking_price: "",noi: "",})
  const [buyerData, setBuyerData] = useState({ list_type: "",property_name: "",property_address: "",type_id: "",asking_cap_rate: "",asking_price: "",noi: "",});
  


  useEffect(() => {
    setSellerData(sellerData => ({ ...sellerData, list_type: formData.seller_list_type }));
    setBuyerData(buyerData => ({ ...buyerData, list_type: formData.list_type }));
   },[]); 


  const handleChange = (value, name) => {
    onSetError({ ...error, [name]: "" });
    onSetFormData({ ...formData, [name]: value });
  };

   const fetchSuggestions = (inputValue, type, listType) => {
    if (inputValue !== "") {
      axios
        .get(`${BASE_URL}/get-datalisting?list=${listType}&search=${inputValue}`, config)
        .then((res) => {
          const data = res?.data?.data;
  
          let options = [];
  
          if (data?.exclusive?.length) {
            const exclusiveOptions = data.exclusive.map((el) => ({
              value: el?.id,
              label: `${el?.property?.property_name || "N/A"}, ${el?.property?.street_address || "N/A"}, ${el?.property?.city || "N/A"}, ${el?.property?.state || "N/A"}`,
              noi: el?.property?.anual_rent ?? "N/A",
              asking_cap_rate: el?.property?.asking_cap_rate,
              asking_price: el?.property?.asking_price,
            }));
            if (listType === "exclusive") {
              options = exclusiveOptions;
              if (type === "seller") {
                setSellerOptions(options);
              } else if (type === "buyer") {
                setBuyerOptions(options);
              }
            }
          }
  
          if (data?.leads?.length) {
            const leadOptions = data.leads.map((el) => ({
              value: el?.id,
              label: `${el?.contact?.first_name || ""} ${el?.contact?.last_name || ""}`.trim(),
            }));
            if (listType === "leads")
               {
              options = leadOptions;
              if (type === "seller") {
                setSellerOptions(options);
              } else if (type === "buyer") {
                setBuyerOptions(options);
              }
            }
          }
  
          if (data?.contact_list?.length) {
            const contactOptions = data.contact_list.map((el) => ({
              value: el?.id,
              label: `${el?.first_name || ""} ${el?.last_name || ""}`.trim(),
            }));
            if (listType === "contacts") {
              options = contactOptions;
              if (type === "seller") {
                setSellerOptions(options);
              } else if (type === "buyer") {
                setBuyerOptions(options);
              }
            }
          }
  
          if (data?.length) {
            const userOptions = data.map((el) => ({
              value: el?.id,
              label: `${el?.first_name || ""} ${el?.last_name || ""}`.trim(),
            }));
  
            if (listType === "BDS_Buyer") {
              options = userOptions;
              setBdsOptions(options);
            } else if (listType === "BDS_Seller") {
              options = userOptions;
              setBdsSellerOptions(options);
            } else if (listType === "Broker_Buyer") {
              options = userOptions;
              setBrBuyerOptions(options);
            } else if (listType === "Broker_Seller") {
              options = userOptions;
              setBrSellerOptions(options);
            }
          }
  
          
        })
        .catch((err) => {
          if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
            NotificationManager.error(err.response?.data?.message);
          }
        });
    }
  };

  const fetchOfferVision = () => {
    setLoading(true);
    axios
      .get(`${BASE_URL}/offer-version-data`, config)
      .then((res) => {
        const data = res?.data?.data; 
       if (!data || typeof data !== "object") {
          setOfferVisionOptions([]); 
          return;
        }
         const options = Object.entries(data).map(([key, value]) => ({
          value: key, 
          label: value 
        }));
  
        setOfferVisionOptions(options);
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      })
      .finally(() => setLoading(false));
  };
  

  useEffect(() => {
      if (showModal) {
        fetchOfferVision();
      }
    }, [showModal]);
  

  
  return (
    <form onSubmit={handleSubmit}>

     <div className="flex gap-4">
       <div className="mt-6 w-[48%]">
          <label className="dark-H head-4 mb-2">Offer Date</label>
           <DatePicker
             className="body-N"
             dateFormat="MM/dd/yyyy"
             placeholderText="MM/DD/YYYY"
             selected={formData.created_at ? new Date(formData.created_at) : null}
             onChange={(value) => {
              onSetError({ ...error, created_at: "" });
              handleChange(value, "created_at");
            }}
           />
           {error?.created_at  && <p className="body-S red-D">{error?.created_at }</p>}
       </div>

       <div className="mt-5 w-[48%]">
          <label className="dark-H head-4 mb-2 required:*:">
            Offer Version 
          </label>
          <div className="mt-1.5">
          <Select
            ref={selectRef}
            className="body-N"
            options={offerVisionOptions}
            placeholder={"Offer version"}
            value={offerVisionOptions?.find((option) => option?.value === formData.offer_version)}
            onChange={(option) => {
              onSetError("offer_version");
              handleChange(option?.value, "offer_version");
            }}
          />
          </div>
          {error?.offer_version  && <span className="body-S red-D">{error?.offer_version }</span>}
        </div>
     </div>
      
      <div className="flex gap-4">
        <div className="mt-5 w-[48%]">
          <label className="dark-H head-4 mb-2 required:*:">
            Broker Representing Seller
          </label>
          <div className="mt-1.5">
          <SearchDropdown
              isTop={false}
              placeholder={`Broker Representing Seller`}
              text={formData?.broker_seller}
              options={brSellerOptions}
              fetchSuggestions={(inputValue) => fetchSuggestions(inputValue, "", 'Broker_Seller')}
              onSetOptions={(value) => setBrSellerOptions(value)}
              handleSelect={(option) => {
                onSetFormData({ ...formData, br_seller_id: option?.value, broker_seller: option?.label } );
                setBrSellerOptions([]);
              }}
               />
          </div>
          {error?.broker_seller  && <span className="body-S red-D">{error?.broker_seller }</span>}
        </div>

        <div className="mt-5 w-[48%]">
          <label className="dark-H head-4 mb-2 required:*:">
            BDS(Seller)
          </label>
          <div className="mt-1.5">
           <SearchDropdown
              isTop={false}
              placeholder={`BDS Seller`}
              text={formData?.bds_seller}
              options={bdsSellerOptions}
              fetchSuggestions={(inputValue) => fetchSuggestions(inputValue, "", 'BDS_Seller')}
              onSetOptions={(value) => setBdsSellerOptions(value)}
              handleSelect={(option) => {
                onSetFormData({ ...formData, bds_seller_id: option?.value, bds_seller	: option?.label } );
                setBdsSellerOptions([]);
              }}
            />
          </div>
          {error?.bds_seller  && <span className="body-S red-D">{error?.bds_seller }</span>}
        </div>
      </div>

      <div className="flex gap-4">
        <div className="mt-5 w-[48%]">
          <label className="dark-H head-4 mb-2 required:*:">
            Broker Representing Buyer
          </label>
          <div className="mt-1.5">
          <SearchDropdown
            isTop={false}
            placeholder={`Broker Representing Buyer`}
            text={formData?.broker_buyer}
            options={brBuyerOptions}
            fetchSuggestions={(inputValue) => fetchSuggestions(inputValue, "", 'Broker_Buyer')}
            onSetOptions={(value) => setBrBuyerOptions(value)}
            handleSelect={(option) => {
              onSetFormData({ ...formData, br_buyer_id: option?.value, broker_buyer: option?.label } );
              setBrBuyerOptions([]);
            }}
             />
          </div>
          {error?.broker_buyer  && <span className="body-S red-D">{error?.broker_buyer }</span>}
        </div>

        <div className="mt-5 w-[48%]">
          <label className="dark-H head-4 mb-2 required:*:">
             BDS (Buyer) 
          </label>
          <div className="mt-1.5">
          <SearchDropdown
              isTop={false}
              placeholder={`BDS Buyer`}
              text={formData?.bds_buyer}
              options={bdsOptions}
              fetchSuggestions={(inputValue) => fetchSuggestions(inputValue, "", 'BDS_Buyer')}
              onSetOptions={(value) => setBdsOptions(value)}
              handleSelect={(option) => {
                onSetFormData({ ...formData, bds_buyer_id: option?.value, bds_buyer: option?.label } );
                setBdsOptions([]);
              }}
               />
          </div>
          {error?.bds_buyer && <span className="body-S red-D">{error?.bds_buyer	 }</span>}
        </div>
      </div>
     
      <div className="flex gap-5">
         <div className="w-[50%] mt-5">
      {from !== "update" && (
        <div className="mb-6">
          <label className="dark-H head-4">Seller</label>
          <div className="mt-2">
            <RadioGroup
              onChange={(value) => {
                setSellerData({ ...sellerData, list_type: value });
                fetchSuggestions("", "seller", value); 
              }}
              value={sellerData.list_type}
            >
              <Stack direction="row" gap={3}>
                <Radio value="exclusive">Exclusive</Radio>
                <Radio value="leads">Lead</Radio>
                <Radio value="contacts">Contact</Radio>
              </Stack>
            </RadioGroup>
            {error?.list_type && <span className="body-S red-D">{error?.list_type}</span>}
          </div>

          <div className="mt-3">
          <SearchDropdown
            isTop={false}
            placeholder={`Search ${sellerData.list_type || "item"} here`}
            text={formData.seller_options}
            options={sellerOptions}
            fetchSuggestions={(inputValue) => fetchSuggestions(inputValue, "seller", sellerData.list_type)}
            onSetOptions={(value) => setSellerOptions(value)}
            handleSelect={(option) => {
              onSetFormData((formData) => {
                if (sellerData.list_type === "exclusive") {
                  return {
                    ...formData,
                    seller_exclusive_id: option?.value,
                    seller_options: option?.label,
                  };
                } else if (sellerData.list_type === "leads") {
                  return {
                    ...formData,
                    seller_lead_id: option?.value,
                    seller_options: option?.label,
                  };
                } else if (sellerData.list_type === "contacts") {
                  return {
                    ...formData,
                    seller_contact_id: option?.value,
                    seller_options: option?.label,
                  };
                }
                return formData;
              });
            
              setSellerOptions([]);
            }}
            
          />
         </div>
        </div>
      )}
        </div>

       <div className="w-[50%] mt-5">
      {from !== "update" && (
        <div className="mb-6">
          <label className="dark-H head-4">Buyer</label>
          <div className="mt-2">
            <RadioGroup
              onChange={(value) => {
                setBuyerData({ ...buyerData, list_type: value });
                fetchSuggestions("", "buyer", value); 
              }}
              value={buyerData.list_type}
            >
              <Stack direction="row" gap={3}>
                <Radio value="exclusive">Exclusive</Radio>
                <Radio value="leads">Lead</Radio>
                <Radio value="contacts">Contact</Radio>
              </Stack>
            </RadioGroup>
            {error?.list_type && <span className="body-S red-D">{error?.list_type}</span>}
          </div>

          <div className="mt-3">
            <SearchDropdown
              isTop={false}
              placeholder={`Search ${buyerData.list_type || "item"} here`}
              text={formData.buyer_options}
              options={buyerOptions}
              fetchSuggestions={(inputValue) => fetchSuggestions(inputValue, "buyer", buyerData.list_type)}
              onSetOptions={(value) => setBuyerOptions(value)}
              handleSelect={(option) => {
                onSetFormData((formData) => {
                  if (buyerData.list_type === "exclusive") {
                    return {
                      ...formData,
                      buyer_exclusive_id: option?.value,
                      buyer_options: option?.label,
                    };
                    } 
                  else if (buyerData.list_type === "leads") {
                    return {
                      ...formData,
                      buyer_lead_id: option?.value,
                      buyer_options: option?.label,
                    };
                  } else if (buyerData.list_type === "contacts") {
                    return {
                      ...formData,
                      buyer_contact_id: option?.value,
                      buyer_options: option?.label,
                    };
                  }
                  return formData;
                });           
  
                setBuyerOptions([]);
              }}
            />
           

            {/* {buyerData.type_id && (
              <div className="flex justify-between items-center head-6 dark-H mt-1">
                <p>NOI: <span className="body-S">{selectedOptionData.noi ? `$ ${selectedOptionData.noi.toLocaleString()}` : "N/A"}</span></p>
                <p>Asking Cap Rate: <span className="body-S">{selectedOptionData.asking_cap_rate ? `${selectedOptionData.asking_cap_rate.toLocaleString()} %` : "N/A"}</span></p>
                <p>Asking Price: <span className="body-S">{selectedOptionData.asking_price ? `$ ${selectedOptionData.asking_price.toLocaleString()}` : "N/A"}</span></p>
              </div>
            )} */}
            {error?.type_id && <span className="body-S red-D">{error?.type_id}</span>}
          </div>
        </div>
      )}
       </div>
      </div>
      
      <div className="flex gap-4">
        <div className="w-[50%]">
          <label className="dark-H head-4 mb-2 ">
            Offer Cap Rate<span className="red-D">*</span>
          </label>
          <InputWithIcon
            icon={percentage}
            type="number"
            placeholder="Offer Cap Rate"
            value={formData?.offer_cap_rate}
            onChange={(e) => {
              const value = e.target.value;
              var regex = /^\d*\.?\d{0,2}$/;
              if (value > 100) {
                return;
              } else {
                if (regex.test(value) || value === "") {
                  handleChange(value, "offer_cap_rate");
                }
              }
            }}
            onWheel={(e) => e.target.blur()}
          />

          {error?.offer_cap_rate && <span className="body-S red-D">{error?.offer_cap_rate}</span>}
        </div>

        <div className="w-[50%]">
          <label className="dark-H head-4 mb-2">
            Offer Price<span className="red-D">*</span>
          </label>

          <InputWithIcon
            icon={dollor}
            type="number"
            placeholder="Offer Price"
            value={formData?.offer_price}
            onChange={(e) => {
              const value = e.target.value;
              var regex = /^\d*\.?\d{0,2}$/;
              if (regex.test(value) || value === "") {
                handleChange(value, "offer_price");
              }
            }}
            onWheel={(e) => e.target.blur()}
          />

          {error?.offer_price && <span className="body-S red-D">{error?.offer_price}</span>}
        </div>
      </div>

      <div className="mt-6">
        <button type="submit" disabled={disable} className="save-button light-L head-5 green-bg-H">
          Save
        </button>
        <button type="button" onClick={handleClose} className="green-H ml-5">
          Cancel
        </button>
      </div>
    </form>
  );
};

export default AddOfferData;

