import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import useAuth from "../../../hooks/useAuth";
import Loader from "../../../components/Loader";
import { BASE_URL } from "../../../utils/Element";
import Modal from "../../../components/Modal/Modal";
import { NotificationManager } from "react-notifications";

const LinkCompaniesModal = ({ showModal, onClose, onLink, from, selectedIds, isWidth = false }) => {
  const { id } = useParams();
  const [config] = useAuth();
  const [search, setSearch] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
    const [selectedCompanies, setSelectedCompanies] = useState([]);

  useEffect(() => {
    if (selectedIds) {
    setSelectedCompanies(selectedIds);
    }
  }, [selectedIds]);

  const fetchSearchResults = () => {
    if (search.trim() !== "") {
      const endpoint = `${BASE_URL}/get-datalisting?list=companies&search=${search}&company_link_id =${id}`;
      setLoading(true);
      axios
        .get(endpoint, config)
        .then((res) => {
          setSearchResults(res?.data?.data?.company_list || []);
        })
        .catch((err) => {
          setSearchResults([]);
          setError("Error fetching search results. Please try again.");
          if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
            NotificationManager.error(err.response?.data?.message);
          }
        })
        .finally(() => setLoading(false));
    } else {
      setSearchResults([]);
    }
  };

  useEffect(() => {
    if (search !== "") {
      fetchSearchResults();
    }
  }, [search]);

  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  const handleContactCheckboxChange = (company) => {
    const { id, company_name } = company;
    const companyName = `${company_name}`;

    setSelectedCompanies((prevSelectedCompanies) => {
      const index = prevSelectedCompanies?.findIndex((el) => el.id === id);
      const newSelectedCompanies = index !== -1 ? prevSelectedCompanies.filter((el) => el.id !== id) : [...prevSelectedCompanies, { id: id, name: companyName }];

      return newSelectedCompanies;
    });
  };

  const handleClose = () => {
    onClose();
    setSearchResults([]);
    setSearch("");
  };

  const handleLinkButtonClick = () => {
    onLink(selectedCompanies);
    handleClose();
  };

  return (
    <Modal title={"Link Companies"} desc={"Select Companies to link to this task."} show={showModal} onClose={handleClose} className="linkContacts-modal" height={isWidth ? "500px" : "400px"} width={isWidth ? "500px" : "400px"} zIndex="1050">
      <form onClick={handleModalClick}>
        <div>
          <input
            className="body-N"
            type="text"
            placeholder="Search company name here"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              setError(null);
            }}
          />
        </div>
        {error && <p className="red-D">{error}</p>}

        <div className="link-contact-list mt-6" style={{ height: `${isWidth ? "250px" : "150px"}` }}>
          {loading ? (
            <Loader />
          ) : (
            searchResults.map((company) => (
              <label key={company.id} className="container flex flex-col items-start mb-2">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    checked={selectedCompanies?.map((el) => el.id)?.includes(company.id)}
                    onChange={() => {
                      handleContactCheckboxChange(company);
                    }}
                  />
                  <span className="checkmark mr-2"></span>
                  <p className="head-6">{`${company.company_name}`}</p>
                </div>
              </label>
            ))
          )}
        </div>

        <div className="mt-6">
          <button type="button" onClick={handleLinkButtonClick} className="save-button light-L head-5 green-bg-H">
            Link
          </button>
          <button type="button" onClick={handleClose} className="green-H ml-5">
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default LinkCompaniesModal;
