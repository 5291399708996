import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import useAuth from "../../../hooks/useAuth";
import { BASE_URL } from "../../../utils/Element";
import phone from "../../../assets/svgs/call.svg";
import EditCompanyModal from "../EditCompanyModal";
import { formatPhoneNumber } from "../../../utils/utils";
import { NotificationManager } from "react-notifications";
import ActionsMenu from "../../../components/ActionsMenu";
import DeleteConfirmationModal from "../../../components/ConfirmationModals/DeleteConfirmationModal";

const   CompanyDetailHeader = ({ companyDetails, onSetCallApiAgain }) => {
  const { id } = useParams();
  const [config] = useAuth();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [disableDelete, setDisableDelete] = useState(false);

  const handleDelete = () => {
    setDisableDelete(true);
    axios
      .get(`${BASE_URL}/delete-company/${id}`, config)
      .then((res) => {
        if (res.data.success) {
          navigate("/companies");
        }
        setShowDeleteModal(false);
        if (res?.data?.message) {
          NotificationManager.success(res?.data?.message);
        }
      })
      .catch((err) => {
        if (err.response?.data?.message && err.response?.data?.message !== "Too Many Attempts.") {
          NotificationManager.error(err.response?.data?.message);
        }
      })
      .finally(() => setDisableDelete(false));
  };

  return (
    <div className="contact-details light-bg-L">
      <div className="md:flex items-start justify-between">
        <div>
          <p className="head-2 dark-H normal-case">{companyDetails?.company_name}</p>
          <p className="body-N dark-M">
          company info updated - {moment(companyDetails?.last_updated_date).tz('America/New_York').format("MM/DD/YYYY")}
           </p>
        </div>

        <div className="mt-5 md:mt-0">
          {companyDetails?.communication?.length !== 0 && (
            <div className="flex items-center gap-2">
              {companyDetails?.communication
                ?.filter((el) => el !== "")
                ?.flatMap((tag, i) => (
                  <p key={i} className="tags green-H body-S">
                    {tag}
                  </p>
                ))}
            </div>
          )}
        </div>

        <div className="mt-5 md:mt-0">
          <ActionsMenu
            handleEdit={() => {
              setShowModal(true);
            }}
            handleDelete={() => {
              setShowDeleteModal(true);
            }}
          />
        </div>
      </div>

      <div className="mt-6 md:flex justify-between body-N">
        <div>
          <p className="dark-M">Tax Record </p>
          <p className="dark-H">{companyDetails?.tax_record_sent ? moment(companyDetails?.tax_record_sent).format("MM/DD/YYYY") : ""}</p>
        </div>

        {/* <div className="mt-5 md:mt-0">
        <p className="body-N dark-M">Last Update Date</p>
        <p className="body-N dark-H">{moment(companyDetails?.last_updated_date).format("MM/DD/YYYY")}</p>
      </div> */}

        <div className="mt-5 md:mt-0">
          <p className="dark-M">Website URL</p>
          <p
            role="button"
            className="green-H"
            onClick={() => {
              const websiteLink = companyDetails?.website_link;
              const validWebsiteLink = websiteLink && (websiteLink.startsWith('http://') || websiteLink.startsWith('https://')) 
              ? websiteLink 
              : `http://${websiteLink}`;
              window.open(validWebsiteLink, "_blank");
            }}
          >
            {companyDetails?.website_link?.slice(0, 35)}
          </p>
        </div>

        <div className="mt-5 md:mt-0">
          <p className="dark-M">Address</p>

          {companyDetails?.address?.[0]?.street ? (
            <p className="dark-H flex items-center gap-2">
              {companyDetails?.address !== null ? `${companyDetails?.address?.[0]?.street}, ${companyDetails?.address?.[0]?.city}, ${companyDetails?.address?.[0]?.state} ${companyDetails?.address?.[0]?.zip_code}` : "N/A"}
              {companyDetails?.address?.[0]?.address_category && <div className="body-XS green-H tag-data capitalize ml-1">{companyDetails?.address?.[0]?.address_category}</div>}
            </p>
          ) : (
            "N/A"
          )}
        </div>

        <div className=" mt-5 md:mt-0">
        <p className="dark-M">Phone</p>
          <div className="flex items-center">
          <img src={phone} alt="icon" className="sidebar-icons" />
          {companyDetails?.phone?.[0]?.phone_number ? (
            <p className="dark-H flex items-center gap-2">
              {/* {companyDetails?.phone?.[0]?.country_code}  */}
              {formatPhoneNumber(companyDetails?.phone?.[0]?.phone_number)}
               {companyDetails?.phone?.[0]?.ext && <span className="body-S dark-M">ext.
               {companyDetails?.phone?.[0]?.ext}</span>}
              {companyDetails?.phone?.[0]?.phone_category &&
               <div className="body-XS green-H tag-data capitalize ml-1">
                {companyDetails?.phone?.[0]?.phone_category}</div>}

            </p>
          ) : (
            "N/A"
          )}
          </div>
         
        </div>
      </div>

      <div className="mt-6 flex gap-5 body-N">
        <div>
          <p className="dark-M body-N">Phone (Additional)</p>
          
          {companyDetails?.phone
            ?.filter((_, idx) => idx !== 0)
            ?.flatMap((el, i) =>
              el.phone_number ? (
                <div className="flex item-center gap-2">
                  <img src={phone} alt="icon" className="sidebar-icons mt-2" />
                <p key={i} className="dark-H mt-2 flex items-center gap-2">
                  {/* {el?.country_code}  */}
                  {formatPhoneNumber(el?.phone_number)} <span className="dark-M">ext.{el?.ext}</span>
                  <div className="body-XS green-H tag-data capitalize ml-1">{el?.phone_category}</div>
                </p>
                </div>

              ) : null
            )}
           </div>
         

        <div>
          <p className="dark-M body-N">Address (Additional)</p>
          {companyDetails?.address
            ?.filter((_, idx) => idx !== 0)
            ?.flatMap((el, i) => (
              <p key={i} className="body-N dark-H mb-1 flex items-center">
                {el?.street}, {el?.city}, {el?.state}, {el?.zip_code}
                {el?.address_category && <div className="body-XS green-H tag-data ml-1">{el?.address_category}</div>}
              </p>
            ))}
        </div>
      </div>

      <div className="body-N mt-6">
        <p className="dark-M">Comments</p>
        <p className="dark-H">{companyDetails?.comment ?? "N/A"}</p>
      </div>

      <EditCompanyModal
        showModal={showModal}
        onSetCallApiAgain={onSetCallApiAgain}
        onClose={() => {
          setShowModal(false);
        }}
      />

      <DeleteConfirmationModal showModal={showDeleteModal} disable={disableDelete} onClose={() => setShowDeleteModal(false)} handleDelete={handleDelete} />
    </div>
  );
};

export default CompanyDetailHeader;
